<div id="inputLabel" *ngIf="vcpmSer.inputChanged">
    <span class="warning-span">
      <clr-icon shape="exclamation-triangle" size="20"></clr-icon> {{'modified.title' | translate}}
    </span>
</div>
<div class="clr-row footer">
    <div class="footer-links-wrapper">
        <div class="copyrightwidth">
            <div class="center">{{copyRightText}}</div>
        </div>
        <div class="footer-links align-links">
            <span><a href="https://www.vmware.com/help/legal.html">{{ 'terms.title' | translate }}</a></span>
            <span><a href="https://www.vmware.com/help/privacy.html">{{ 'privacy.title' | translate }}</a></span>
            <span><a href="https://www.vmware.com/help/privacy/california-privacy-rights.html">{{ 'privacyRights.title' | translate }}</a></span>
            <span> <a href="https://www.vmware.com/help/accessibility.html">{{ 'accessibility.title' | translate }}</a></span>
            <span><a href="https://www.vmware.com/help/trademarks.html">{{ 'trademarks.title' | translate }}</a></span>
            <span> <a href="https://www.vmware.com/topics/glossary">{{ 'gloassary.title' | translate }}</a></span>
            <span><a href="https://www.vmware.com/help.html">{{ 'help.title' | translate }}</a></span>
        </div>
    </div>
</div>