import {Component, forwardRef, Inject, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AreaQuestions } from '@core/models/questions';
import { WhitneyService } from '@core/services/whitney.service';
import { Label } from 'ng2-charts';
import { Chart, ChartDataSets, ChartType, RadialChartOptions } from 'chart.js';
import chartTrendline from "chartjs-plugin-trendline";
import { PDFService } from '@core/services/pdf.service';
import { EnvironmentsService } from '@core/services/environments.service';
import Plotly from 'plotly.js-dist'
import wrapText from "wrap-text";
import { formatDate } from '@angular/common';
import csv from 'csvtojson';
import { ClrLoadingState } from '@clr/angular';
import { TranslateService } from '@ngx-translate/core';
import { VCPMService } from '@core/services/vcpm.service';
import { AlertService } from '@core/services/alert.service';
import { AuthenticationService } from '@core/services/authentication.service';
import { Data, VCPMResponse } from '@core/models/vcpmresponse';
import { ExportToCsv } from 'export-to-csv';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {t
  urlRegex = /https?:\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g;
  questions: AreaQuestions[];
  loading: boolean = false;
  errorMsg: string;
  showEmailVerificaion: boolean = false;
  showImportExport: boolean = false;
  fileToUpload: any;
  importError: string;
  importBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;
  showBanner: boolean = false;
  // Define the end date to show the banner (Year, Month-1, Day)
  private endDate: Date = new Date(2024, 11, 15); // Example: December 15, 2024

  @ViewChild('radarChart', { static: true }) radarChart: any;
  heatmap: any;

  radarChartLabels: Label[] = [];
  radarChartType: ChartType = 'radar';
  radarChartData: ChartDataSets[] = [];
  radarChartOptions: RadialChartOptions = {
    responsive: true,
    legend: {
      display: false
    },
    defaultColor: 'rgb(255, 228, 236)',
    scale: {
      animate: true,
      gridLines: {
        color: 'rgba(243, 251, 255, 0.2)'
      },
      angleLines: {
        color: 'rgba(243, 251, 255, 0.2)'
      },
      pointLabels: {
        callback: (label: any) => {
          const words = label.split(' ');
          if (words.length < 3) {
            return label;
          }
          return [words.slice(0, 3).join(' '), words.slice(3).join(' ')]
        },
        fontSize: 12,
        fontColor: '#F3FBFF',
      },
      ticks: {
        beginAtZero: true,
        min: 0,
        stepSize: 0.5,
        fontColor: '#F3FBFF',
        backdropColor: '#1b2a32',
      }
    }
  };
  spanishLangSel: boolean;
  saveBtnState: any;
  flag: boolean;
  projectNameWithId: any;
  formName: any;
  btnText: any;
  openSaveDialog: boolean;

  constructor(private router: Router, private whitneyService: WhitneyService, private pdfService: PDFService,
              @Inject(forwardRef(() => EnvironmentsService)) public env: EnvironmentsService,
              private translateService: TranslateService, public vcpmSer: VCPMService,
    private alertService: AlertService, private authService: AuthenticationService,private route: ActivatedRoute) {
    Chart.plugins.register(chartTrendline);
  }

  ngOnInit(): void {
    this.loading = true;
    console.log(window.location.href);
    if(window.location.href.includes('/results/')){
      this.route.params.subscribe(params => {
        this.vcpmSer.getAnalysisDetails(params['projectId']).subscribe((resp:any) => {
          this.vcpmSer.analysisName = resp.metadata.analysisName;
          this.projectNameWithId = this.vcpmSer.analysisName +' (' +this.translateService.instant('analysisid.title') +' : '+params['projectId']+')'
          this.vcpmSer.vcpmSavedData = resp;
          if(this.vcpmSer.isProjectSaved){//when a new analysis is created at that time isProjectSaved is settting to true 
            this.vcpmSer.isProjectSaved = false;
            if(this.whitneyService.isHomeClick){
              this.alertService.success(this.translateService.instant('createSuccessMsg.title'),true);
            }else{
              this.alertService.success(this.translateService.instant('saveSuccessMsg.title'),true);
            }
          }else if(this.vcpmSer.questionsPage){
            this.vcpmSer.questionsPage = false;
            this.alertService.success(this.translateService.instant('saveSuccessMsg.title'),true);
          }
          this.prepareQuestions();
        },error => {
          this.alertService.error(this.translateService.instant('apiFailErrorMsg.title'),false)
        })
        });
    }else{
      this.prepareQuestions();
    }
  }

  prepareQuestions(){
    this.whitneyService.getQuestions().subscribe(
      result => {
        this.questions = result;
        if(this.translateService.currentLang === 'es'){
          this.spanishLangSel = true;
        }else{
          this.spanishLangSel = false;
        }
        this.whitneyService.refreshAverage();
        if(this.vcpmSer.vcpmSavedData !== undefined && this.vcpmSer.vcpmSavedData.data.inputData.length > 0 && 
          (this.vcpmSer.inputChanged === false || this.vcpmSer.inputChanged == undefined)){
          this.mergejson();
        }else{
          this.generateRadarChart();
          this.generateHeatMap();
          this.whitneyService.questionsResp = result;
          this.whitneyService.questionsCopy = JSON.parse(JSON.stringify(result));
          if(this.vcpmSer.openSaveDialog){
            this.vcpmSer.openSaveDialog = false;
            this.onSaveBtnClick(true);
          }
          this.loading = false;
        }
      },
      error => {
        this.loading = false;
        console.log(error);
        this.errorMsg = 'Failed to get the questions, please try again later!'
      }
    );
  }

  generateRadarChart() {
    this.radarChartLabels = [];
    const currentData = [];
    const goalData = [];
    this.questions.forEach(area => {
      this.radarChartLabels.push(area.description);
      currentData.push(area.currentAverage);
      goalData.push(area.goalAverage);
    })
    this.radarChartData = [
      {
        data: goalData,
        label: this.translateService.instant('customerGoals.title'),
        backgroundColor: 'rgba(230, 166, 107, 0.5)',
        borderColor: 'rgb(230, 166, 107)',
        borderWidth: 1,
        fill: true,
        pointBackgroundColor: 'rgb(230, 166, 107)',
        pointBorderColor: '#FFFFFF',
        pointHoverBackgroundColor: '#FFFFFF',
        pointHoverBorderColor: 'rgb(230, 166, 107)'
      },
      {
        data: currentData,
        label: this.translateService.instant('current.title'),
        fill: true,
        backgroundColor: 'rgba(105, 228, 236, 0.5)',
        borderColor: 'rgb(105, 228, 236)',
        borderWidth: 1,
        pointBackgroundColor: 'rgb(105, 228, 236)',
        pointBorderColor: '#FFFFFF',
        pointHoverBackgroundColor: '#FFFFFF',
        pointHoverBorderColor: 'rgb(105, 228, 236)'
      }
    ];

  }

  generateHeatMap() {
    const xLabel = [];
    const yLabel = [];
    const zValues = [];
    const layout = {
      autosize: true,
      annotations: [],
      margin: {
        r: 20,
        t: 20,
        b: 20
      },
      yaxis: { ticks: '', ticksuffix: ' ', fixedrange: true, color: '#F3FBFF', ticklabeloverflow: 'allow', automargin: true },
      xaxis: { ticks: '', fixedrange: true, color: '#F3FBFF' },
      scale: { color: '#F3FBFF' },
      plot_bgcolor: "#1b2a32",
      paper_bgcolor: "#1b2a32"
    };
    let allGaps = []
    this.questions.forEach(area => {
      yLabel.push(area.description);
      const gapValues = [];
      let index = 1;
      area.details.forEach(ela => {
        const current = ela.currentVal.selected || ela.currentVal.default;
        const goal = ela.goalVal.selected || ela.goalVal.default;
        const gap = (Number(goal) - Number(current)).toFixed(2);
        gapValues.push(gap);
        const x = this.translateService.instant('question.title')+ ` #${index}`;
        if (!xLabel.includes(x)) {
          xLabel.push(x);
        }
        const text = wrapText(ela.shortDesc, 60).replace('\n', '</br></br>').replace(/\n/g, '</br>');
        var result = {
          xref: 'x1',
          yref: 'y1',
          x,
          y: area.description,
          text: gap,
          font: {
            family: 'Arial',
            size: 12,
            color: "black"
          },
          showarrow: false,
          hoverinfo: 'z',
          hovertext: text,
          hoverlabel: { align: 'left', bgcolor: 'black', bordercolor: 'black', font: { color: 'white' }, namelength: 3 }
        };
        layout.annotations.push(result);
        index += 1;
      })
      zValues.push(gapValues);
      allGaps = allGaps.concat(gapValues);
    })

    let maxGap = Math.max.apply(Math, allGaps);
    let minGap = Math.min.apply(Math, allGaps);
    if (minGap == maxGap) {
      maxGap = 5;
      minGap = -5;
    }

    var colorscaleValue = [
      [0, '#71bc65'],
      [0.5, '#fcea91'],
      [1, '#e7716f']
    ];

    var data = [
      {
        z: zValues.reverse(),
        zmin: minGap,
        zmax: maxGap,
        x: xLabel,
        y: yLabel.reverse(),
        type: 'heatmap',
        hoverinfo: 'none',
        colorscale: colorscaleValue,
        showscale: false
      }
    ];

    const config = {
      displayModeBar: false,
    };

    Plotly.newPlot('heatmap', data, layout, config).then(gd => {
      this.heatmap = gd;
      Plotly.Plots.resize(this.heatmap);
      window.onresize = function () {
        Plotly.Plots.resize(this.heatmap);
      };
    });
  }

  breakString(str, maxChars) {
    if (str.length > maxChars && str.includes(' ')) {
      let index = str.lastIndexOf(' ', maxChars);
      if (index === -1) index = str.indexOf(' ', maxChars + 1);
      return str.substr(0, index) + '</br>' + this.breakString(str.substr(index + 1), maxChars);
    } else {
      return str;
    }
  }

  downloadReport() {
    const gapTableData = [];
    const questions = [];
    this.questions.forEach(area => {
      gapTableData.push([area.description, area.currentAverage, area.goalAverage, (Number(area.goalAverage) - Number(area.currentAverage)).toFixed(2)])

      const questionsData = [];
      area.details.forEach(question => {
        questionsData.push([question.series, question.shortDesc, question.currentVal.selected || question.currentVal.default, question.goalVal.selected || question.goalVal.default])
      });

      questions.push({
        type: 'table',
        heading: area.description,
        headers: [this.translateService.instant('sno.title'), area.description, this.translateService.instant("current.title"), this.translateService.instant("goal.title")],
        data: questionsData
      })
    });

    const radarChart = this.radarChart.nativeElement.toDataURL('image/png');
    let radarImg;
    let heatMapImg;
    if(this.translateService.currentLang === 'zh'){
      radarImg = 'radar_chinese';
      heatMapImg = 'heatmap_chinese'
    }else if(this.translateService.currentLang === 'ja'){
      radarImg = 'radar_japanese';
      heatMapImg = 'heatmap_japanese'
    }else if(this.translateService.currentLang === 'es'){
      radarImg = 'radar_spanish';
      heatMapImg = 'heatmap_spanish';
    }else{
      radarImg = 'radar'
      heatMapImg = 'heatmap'
    }
    Plotly.toImage(this.heatmap, { format: 'png', height: 600, width: 1000 }).then(heatmap => {
      let pdfData = [
        {
          type: 'table',
          heading: this.translateService.instant("summary.title"),
          headers: [this.translateService.instant("name.title"), this.translateService.instant("current.title"), this.translateService.instant("goal.title"), this.translateService.instant("gap1.title")],
          data: gapTableData
        },
        {
          type: 'image',
          graph: radarImg,
          heading: this.translateService.instant("analysis.title"),
          data: radarChart
        },
        {
          type: 'image',
          graph: heatMapImg,
          heading: this.translateService.instant("analysis.title"),
          data: heatmap
        }
      ];
      pdfData = pdfData.concat(questions);
      this.pdfService.downloadPDF(pdfData);

      // Send event to Matomo
      if(this.whitneyService.getUserInfo() === undefined && this.isLoggedin()){
        let companyName = undefined;
        this.whitneyService.validatedUser = { useremail: this.authService.currentUserValue.email, companyName };
      }
      const userInfo = this.whitneyService.getUserInfo();
      if(userInfo.companyName !== undefined){
        (<any>window)._paq.push(['trackEvent', 'Assessment', 'Download Report', `User Email: ${userInfo.useremail}, Company: ${userInfo.companyName}, Language: ${this.env.selectedLang}`]);
      }else{
        (<any>window)._paq.push(['trackEvent', 'Assessment', 'Download Report', `User Email: ${userInfo.useremail}, Language: ${this.env.selectedLang}`]);
      }
    });
  }

  onDownload() {
    if(this.isLoggedin()){
      this.downloadReport();
    }else{
      if (this.whitneyService.hasValidatedEmail()) {
        this.downloadReport();
       } else {
         this.showEmailVerificaion = true;
       }
    }

  }

  onImportAndExport() {
    this.showImportExport = true;
    this.importError = undefined;
    console.log(this.fileToUpload);
    if (this.fileToUpload) {
      this.fileToUpload.value = '';
    }
  }

  handleFileInput(event) {
    this.fileToUpload = event.target;
  }

  exportToCSV() {
    const data = []
    this.questions.forEach(area => {
      data.push({
        area: area.description,
        question: '',
        current: '',
        goal: '',
        description: ''
      })
      area.details.forEach(question => {
        data.push({
          area: '',
          question: question.shortDesc,
          current: question.currentVal.selected || question.currentVal.default,
          goal: question.goalVal.selected || question.goalVal.default,
          description: question.assessmentInfo.map(x => `${x.id}. ${x.description}\n\n`).join('')
        })
      })
      data.push({
        area: '',
        question: '',
        current: '',
        goal: '',
        description: ''
      })
    });
    let filename;
    if(this.translateService.currentLang === 'zh'){
      filename = 'Private Cloud Maturity Model Assessment Metadata Chinese-' + formatDate(new Date(), 'yyyyMMddhhmmsss', 'en_US');
    }else if(this.translateService.currentLang === 'ja'){
      filename = 'Private Cloud Maturity Model Assessment Metadata Japanese' + formatDate(new Date(), 'yyyyMMddhhmmsss', 'en_US');
    }else if(this.translateService.currentLang === 'es'){
      filename = 'Private Cloud Maturity Model Assessment Metadata Spanish' + formatDate(new Date(), 'yyyyMMddhhmmsss', 'en_US');
    }else{
      filename = 'Private Cloud Maturity Model Assessment Metadata-' + formatDate(new Date(), 'yyyyMMddhhmmsss', 'en_US');
    }

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: this.translateService.instant('doNotModify.title'),
      useTextFile: false,
      useBom: true,
      filename: filename,
      headers: [this.translateService.instant('area.title'), this.translateService.instant('question.title'), this.translateService.instant('current.title'), this.translateService.instant('goal.title'), this.translateService.instant('desc.title')],
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(data);
  }

  async importCSV() {
    let hasValidData = false;
    this.importError = undefined;
    if (!this.fileToUpload?.files?.length) {
      this.importError = this.translateService.instant('addCSVFile.title');
      return;
    }
    this.importBtnState = ClrLoadingState.LOADING;
    this.fileToUpload.files[0].text()
      .then(csvStr => {
        csv({
          noheader: true,
          output: "csv"
        })
          .fromString(csvStr)
          .then(csvRows => {
            try {
              let area: AreaQuestions = undefined;
              csvRows?.forEach(
                (row: string[]) => {
                  if (row.length == 5) {
                    if (row[0] && !row[1]) {
                      area = this.questions.find(x => x.shortDesc == row[0]);
                    } else if (area && !row[0] && row[1]) {
                      const question = area.details.find(question => question.shortDesc == row[1])
                      if (question) {
                        const current = row[2];
                        const goal = row[3];
                        if (current && Number(current) && 1 <= Number(current) && Number(current) < 6) {
                          question.currentVal.selected = Number(current);
                          hasValidData = true;
                        }
                        if (goal && Number(goal) && 1 <= Number(goal) && Number(goal) < 6) {
                          question.goalVal.selected = Number(goal);
                          hasValidData = true;
                        }
                      }
                    }
                  }
                }
              )
              if (!hasValidData) {
                this.importError = this.translateService.instant('incorrectFormat.title');
                this.importBtnState = ClrLoadingState.ERROR;
              } else {
                this.whitneyService.refreshAverage();
                this.generateRadarChart();
                this.generateHeatMap();
                  if(this.vcpmSer.vcpmSavedData !== undefined){
                    this.checkWhetherDataModifiedOrnot(this.vcpmSer.vcpmSavedData.data.inputData);
                  }else{
                    this.checkWhetherDataModifiedOrnot(this.whitneyService.questionsCopy);
                  }
                this.showImportExport = false;
                this.importBtnState = ClrLoadingState.SUCCESS
              }
            } catch (error) {
              console.log(error);
              this.importError = this.translateService.instant('importErr.title');
              this.importBtnState = ClrLoadingState.ERROR;
            }
          })
      })
      .catch(error => {
        console.log(error);
        this.importError = this.translateService.instant('importErr.title');
        this.importBtnState = ClrLoadingState.ERROR;
      })
  }
  onSaveBtnClick(value: boolean){
    if(this.isLoggedin()){
      this.saveBtnState = value;
      this.formName = this.translateService.instant('saveAnalysis.title');
      this.btnText = this.translateService.instant('save.title');
      if(this.vcpmSer.vcpmSavedData !== undefined){
        let languageChanged = this.vcpmSer.checkWhetherLanguageChangedOrNot();
        if(this.vcpmSer.inputChanged || languageChanged){
          let inputData = this.whitneyService.prepareInputData()
          let req = {
            "metadata" : this.vcpmSer.vcpmSavedData.metadata,
            "data":inputData
          }
          this.vcpmSer.updateAnalysis(this.vcpmSer.vcpmSavedData.projectId,req).subscribe((res : VCPMResponse) => {
            this.vcpmSer.vcpmSavedData = res;
            // this.pushDataToSuperCollider(res);
            this.vcpmSer.inputChanged = false;
            this.alertService.success(this.translateService.instant('saveSuccessMsg.title'),true);
          },error => {
            this.alertService.error(this.translateService.instant('apiFailErrorMsg.title'),false)
          });
        }
      }
      if(!value && this.vcpmSer.isProjectSaved){
        this.router.navigate([`/tools/vmccma/results/${this.vcpmSer.vcpmSavedData.projectId}`])
      }
    }else{
      this.vcpmSer.redirectPage = '/tools/vmccma/results';
      this.vcpmSer.navigateToLogin();
    }

  }
  pushDataToSuperCollider(res){
    let req = {
      "superColliderData": res,
      "configurationGroup": "vmcam",
    }
    this.vcpmSer.pushDataToSuperCollider(req,res.projectId).subscribe(res => {
        this.vcpmSer.inputChanged = false;
        this.alertService.success(this.translateService.instant('saveSuccessMsg.title'),true);
    },error => {
        this.alertService.error(this.translateService.instant('apiFailErrorMsg.title'),true);
    }
    );
  }
  mergejson(){
    for(let i in this.questions){
      this.questions[i].currentAverage = this.vcpmSer.vcpmSavedData.data.inputData[i].currentAverage;
      this.questions[i].goalAverage = this.vcpmSer.vcpmSavedData.data.inputData[i].goalAverage;
      this.questions[i].gap = this.vcpmSer.vcpmSavedData.data.inputData[i].gap;
      this.questions[i]['gapPercent'] = this.vcpmSer.vcpmSavedData.data.inputData[i].gapPercent;
      for(let j in this.questions[i].details){
        if(this.vcpmSer.vcpmSavedData.data.inputData[i].details[j].currentVal.default !== undefined){
          this.questions[i].details[j].currentVal.default = this.vcpmSer.vcpmSavedData.data.inputData[i].details[j].currentVal.default;
        }else if(this.vcpmSer.vcpmSavedData.data.inputData[i].details[j].currentVal.selected !== undefined){
          this.questions[i].details[j].currentVal.default = this.vcpmSer.vcpmSavedData.data.inputData[i].details[j].currentVal.selected;
        }else{
          this.questions[i].details[j].currentVal.default = this.vcpmSer.vcpmSavedData.data.inputData[i].details[j].currentVal
        }
        if(this.vcpmSer.vcpmSavedData.data.inputData[i].details[j].goalVal.default !== undefined){
          this.questions[i].details[j].goalVal.default = this.vcpmSer.vcpmSavedData.data.inputData[i].details[j].goalVal.default;
        }else if(this.vcpmSer.vcpmSavedData.data.inputData[i].details[j].goalVal.selected){
          this.questions[i].details[j].goalVal.default = this.vcpmSer.vcpmSavedData.data.inputData[i].details[j].goalVal.selected;
        }else{
          this.questions[i].details[j].goalVal.default = this.vcpmSer.vcpmSavedData.data.inputData[i].details[j].goalVal
        }
      }
    }
          this.generateRadarChart();
          this.generateHeatMap();
          this.whitneyService.questionsResp = this.questions;
          this.loading = false;
  }

  isLoggedin(){
    return this.authService.currentUserValue !== null && this.authService.currentUserValue !== undefined
  }
  navigateToQuestionsPage(desc){
    if(window.location.href.includes('/results/')){ //pasing projectid in the url if it is a saved project
      this.route.params.subscribe(params => {
        this.router.navigate([`/questions/${params.projectId}/${desc}`])
      });
    }else{//if it is not a saved project then we are not passing projectid
      this.router.navigate([`/questions/${desc}`])
    }
  }
  checkWhetherDataModifiedOrnot(previousData){
    for(let i in this.questions){
      if((this.questions[i].currentAverage == previousData[i].currentAverage) &&
      (this.questions[i].goalAverage == previousData[i].goalAverage) &&
      (this.questions[i].gap == previousData[i].gap) &&
      (this.questions[i]['gapPercent'] == previousData[i].gapPercent)
      ){
        this.vcpmSer.inputChanged = false;
        return;
      }else{
        this.vcpmSer.inputChanged = true;
        return;
      }    
    }
  }

  checkShowBanner() {
    const today = new Date();
    // Check if today is before or on the end date
    this.showBanner = today <= this.endDate;
    return this.showBanner;
  }

}
