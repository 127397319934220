import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { VCPMResponse } from '@core/models/vcpmresponse';
import { AlertService } from '@core/services/alert.service';
import { VCPMService } from '@core/services/vcpm.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'clone-project',
  templateUrl: './clone-project.component.html',
  styleUrls: ['./clone-project.component.scss']
})
export class CloneProjectComponent implements OnInit {
  @Input() cloneProjectModal: boolean;
  @Output() closeCloneProject = new EventEmitter<{ clone: boolean }>();
  @Input() projectList: any[];
  cloneProjectData: any;
  cloneProjName: any;
  constructor(public vcpmService : VCPMService, private alertService: AlertService,private translateService: TranslateService) {
    this.cloneProjectData = this.vcpmService.vcpmSavedData;
    this.cloneProjName = this.vcpmService.vcpmSavedData.metadata.analysisName;
   }

  ngOnInit() {
  }
  modalHandler() {
    this.closeCloneProject.emit({ clone: false });
  }
  
  cloneProject() {
    const projectClone = this.cloneProjectData;
    delete projectClone.projectId;
    for (let i = 1; i <= this.projectList.length; i++) {
      const analysisName = projectClone.metadata.analysisName + '-Copy(' + i + ')';
      const projectPresent = this.projectList.filter(project => project.metadata.analysisName === analysisName)[0];
      if (projectPresent === null || projectPresent === undefined) {
          projectClone.metadata.analysisName = analysisName;
          break;
      } else {
        continue;
      }
    }
    projectClone.updatedAt = new Date();
    let user = JSON.parse(localStorage.getItem('currentUser'));
    projectClone.updatedBy = user.email;
    this.vcpmService.saveAnalysis(projectClone).subscribe((resp: VCPMResponse) => {
      // this.pushDataToSuperCollider(resp);
      this.closeCloneProject.emit({ clone: true });
    },error => {
      this.alertService.error(this.translateService.instant('apiFailErrorMsg.title'),false)
    });
  }

  pushDataToSuperCollider(res){
    let req = {
      "superColliderData": res,
      "configurationGroup": "vmcam",
    }
    this.vcpmService.pushDataToSuperCollider(req,res.projectId).subscribe(res => {
      this.closeCloneProject.emit({ clone: true });
    },error => {
      this.alertService.error(this.translateService.instant('apiFailErrorMsg.title'),false)
    });
  }

}
