import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { VCPMResponse } from '@core/models/vcpmresponse';
import { AlertService } from '@core/services/alert.service';
import { Metadata } from '@core/services/metadata';
import { VCPMService } from '@core/services/vcpm.service';
import { WhitneyService } from '@core/services/whitney.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-new-project',
  templateUrl: './new-project.component.html',
  styleUrls: ['./new-project.component.scss']
})
export class NewProjectComponent implements OnInit {
  @Input() modalState: boolean;
  @Output() onModalBtnSelection = new EventEmitter();
  @Input() formName;
  @Input() btnText;
  @ViewChild('combobox', { static: false }) combobox: any;
  customers: Subject<any> = new Subject<any>();
  newProjectForm: any;
  results: any[];
  showErrorMsg:boolean = false
  opportunityIDs: any[];
  loadingUsers: boolean;
  analysisTitle;
  createOrUpdatebtnText: any;
  constructor(private fb: UntypedFormBuilder,private whitneyService : WhitneyService, private vcpmServ:VCPMService, 
    private translateService: TranslateService, private alertService: AlertService, private router: Router) { }

  ngOnInit(): void {
    if(this.vcpmServ.vcpmSavedData !== undefined){//existing Projects
      this.assignValuesToForm();
    }else{//new projects
      this.createForm();
    }
    this.analysisTitle = this.formName;
    this.createOrUpdatebtnText = this.btnText;
    this.customers
          .pipe(debounceTime(500))
          .subscribe((obj) => {
            if (obj) {
              this.getSFDCCustomerDetails(obj,null,false);
            }
      });
  }
  createForm(){
    this.newProjectForm = this.fb.group({
      analysisName:['',Validators.required],
      sfdcId:[''],
      opportunity:['',Validators.required],
      companyName:['',Validators.required],
      testAnalysis:[false],
      tags:[''],
      description:['']
    });
  }
  assignValuesToForm(){
    let testAnalysis;
    if(this.vcpmServ.vcpmSavedData.metadata.testAnalysis === 'Yes'){
      testAnalysis = true
    }else{
      testAnalysis = false
    }
    this.newProjectForm = this.fb.group({
    analysisName:[this.vcpmServ.vcpmSavedData.metadata.analysisName,Validators.required],
    sfdcId:[''],
    opportunity:['',Validators.required],
    companyName:[this.vcpmServ.vcpmSavedData.metadata.companyName,Validators.required],
    testAnalysis:[testAnalysis],
    tags:[this.vcpmServ.vcpmSavedData.metadata.tags],
    description:[this.vcpmServ.vcpmSavedData.metadata.description],
   });
   if(this.vcpmServ.vcpmSavedData.metadata.sfdcId !== ''){
      this.getSfdcId(this.vcpmServ.vcpmSavedData.metadata.sfdcId.id)
   }
  }
  getSfdcId(event){
    this.results = [];
    let sfdc = this.vcpmServ.vcpmSavedData.metadata.sfdcId
    let obj = {
      "searchBy": "CUSTOMER_ID",
      "searchKey": event
    }
    this.getSFDCDetails(obj,sfdc,true)
    
  }
  getSFDCDetails(obj,sfdc,flag){
    if(obj.searchKey.length > 2){
      this.getSFDCCustomerDetails(obj,sfdc,flag);
    }else{
      this.opportunityIDs = [];
      this.newProjectForm.get('sfdcId').setValue('');
      this.showErrorMsg = false;
      this.loadingUsers = false;
    }
  }
  getOpportunities(res,flag){
    let opportunities = res.opportunities;
    if (opportunities !== null || opportunities.length > 0) {
      opportunities.map(item => {
        return {
          id: item.opportunityId,
          name: item.Name
        };
      }).forEach(item => this.opportunityIDs.push(item));
      if(flag && this.vcpmServ.vcpmSavedData !== undefined){
        if(this.opportunityIDs.length > 0){
          for(let i in this.opportunityIDs){
            if(this.opportunityIDs[i].id === this.vcpmServ.vcpmSavedData.metadata.opportunity.id){
              this.newProjectForm.get('opportunity').setValue(this.opportunityIDs[i])
            }
          }
      }
      }
    } else {
      this.opportunityIDs = [];
    }
    this.showErrorMsg = (this.opportunityIDs.length === 0) ? true : false;
    if(this.showErrorMsg && this.newProjectForm.get('testAnalysis').value){
      this.showErrorMsg = false;
    }
  }
  onTypingCustomerID(event){
    if(this.newProjectForm.get('opportunity').value){
      this.newProjectForm.get('opportunity').setValue('');
    }
    this.loadingUsers = true;
    this.results = [];
    let custId;
    if(event.length > 15){
      var str = event;
      var matches = str.match(/(\d+)/)[0];
      custId = matches
    }else{
      custId = event
    }
    let obj = {
      //"searchBy": "CUSTOMER_ID",
      "searchKey": custId
    }
    this.customers.next(obj);
  }
  cancelOrClose(){
    this.onModalBtnSelection.emit(false);
  }
  onChangeopportunityID(event){
    
  }
  getSelectedUserValue(e,flag) {
    this.opportunityIDs = [];
    let result = e;
    if(result !== null && result !== undefined){
      let obj = {
        "partnerId": result.id
      }
      this.whitneyService.getOppurtuniesForPartnerId(obj).subscribe(res => {
        this.getOpportunities(res,flag);
      });
    }
  }
  getSFDCCustomerDetails(obj,sfdc,flag){
    if(obj.searchKey.length > 1){
      this.whitneyService.getPartnerId(obj).subscribe(result => {
        console.log(result);
        if (result !== null || result.length > 0) {
          this.results = [];
          result.map(item => {
            return {
              id: item.partnerId,
              name: item.name,
              fullName: item.partnerId + ' ' + item.name
            };
          }).forEach(item => this.results.push(item));
          if(flag){
            this.newProjectForm.get('sfdcId').setValue(sfdc);
            this.getSelectedUserValue(this.vcpmServ.vcpmSavedData.metadata.sfdcId,flag);
          }
          this.loadingUsers = false;
        } else {
          this.results = [];
          this.newProjectForm.get('sfdcId').setValue('');
          this.loadingUsers = false;
        }
      },error => {
          this.loadingUsers = false;
          this.results = [];
          this.newProjectForm.get('sfdcId').setValue('');
      });
    }else{
      this.opportunityIDs = [];
      this.newProjectForm.get('sfdcId').setValue('');
      this.showErrorMsg = false;
      this.loadingUsers = false;
    }
  }
  disableSaveBtn(){
    if(this.newProjectForm.get('analysisName').status === 'INVALID' || this.newProjectForm.get('companyName').status === 'INVALID'){
      return true
    }
    if(this.newProjectForm.get('testAnalysis').value === true && this.showErrorMsg){
      this.showErrorMsg = false;
      return false;
    }
    if((this.newProjectForm.get('testAnalysis').value === false && (this.newProjectForm.get('sfdcId').value === '' || 
          this.newProjectForm.get('opportunity').value === '')) || this.showErrorMsg){
      return true;
    }else{
      return false;
    }
  }  
  save(){
    if(this.vcpmServ.vcpmSavedData !== undefined){
      this.updateMetaData();
    }else{
      let finalReqObj = this.prepareReqObj();
      this.vcpmServ.saveAnalysis(finalReqObj).subscribe((res: VCPMResponse) => {
        this.pushDataToSuperCollider(res,false);
      },error => {
        this.alertService.error(this.translateService.instant('apiFailErrorMsg.title'),false)
      });
    }
  }
  updateMetaData(){
    let tags = this.getTags();
    let testAnalysis = this.getTestAnalysis();
    this.vcpmServ.vcpmSavedData.metadata.analysisName = this.newProjectForm.get('analysisName').value;
    this.vcpmServ.vcpmSavedData.metadata.tags = tags;
    this.vcpmServ.vcpmSavedData.metadata.description = this.newProjectForm.get('description').value;
    this.vcpmServ.vcpmSavedData.metadata.testAnalysis = testAnalysis;
    this.vcpmServ.vcpmSavedData.metadata.sfdcId = this.newProjectForm.get('sfdcId').value;
    this.vcpmServ.vcpmSavedData.metadata.opportunity = this.newProjectForm.get('opportunity').value;
    this.vcpmServ.vcpmSavedData.metadata.companyName = this.newProjectForm.get('companyName').value;
    this.vcpmServ.vcpmSavedData.metadata.locale = localStorage.getItem('vmccma-lang');
    this.vcpmServ.updateMetadata(this.vcpmServ.vcpmSavedData.projectId,this.vcpmServ.vcpmSavedData).subscribe((resp: VCPMResponse) => {
      // this.pushDataToSuperCollider(resp,true);
      this.vcpmServ.inputChanged = false;
      this.vcpmServ.isProjectSaved = true;
      this.vcpmServ.vcpmSavedData = resp;
      this.vcpmServ.analysisName = resp.metadata?.analysisName;
      this.onModalBtnSelection.emit(true);
      if(this.btnText === this.translateService.instant('create.title')){
        this.whitneyService.isHomeClick = true;
        this.router.navigate([`/results/${resp.projectId}`])
      }
    },error => {
      this.alertService.error(this.translateService.instant('apiFailErrorMsg.title'),false)
    })
  }
  getTags(){
    let tags: Array<string> = [];
    tags = this.whitneyService.stringToArrayConv(this.newProjectForm.get('tags').value);
    return tags;
  }
  getTestAnalysis(){
    let testAnalysis;
    if(this.newProjectForm.get('testAnalysis').value){
      testAnalysis = 'Yes'
    }else{
      testAnalysis = 'No'
    }
    return testAnalysis;
  }
  prepareReqObj(){
    console.log(this.whitneyService.questionsResp);
    let tags = this.getTags();
    let testAnalysis = this.getTestAnalysis();
    let sfdcId  = this.newProjectForm.get('sfdcId').value;
    let metadata = {
      "analysisName": this.newProjectForm.get('analysisName').value,
      "companyName": this.newProjectForm.get('companyName').value,
      "testAnalysis": testAnalysis,
      "sfdcId": sfdcId,
      "tags": tags,
      "description": this.newProjectForm.get('description').value,
      "opportunity": this.newProjectForm.get('opportunity').value,
      "locale": localStorage.getItem('vmccma-lang')
    }
    let inputData = this.whitneyService.prepareInputData();
    let finalReqObj = {
      "metadata":metadata,
      "data":inputData
    }
    return finalReqObj;
  }
  pushDataToSuperCollider(resp,flag){
    let req = {
      "superColliderData": resp,
      "configurationGroup": "vmcam",
    }
    this.vcpmServ.pushDataToSuperCollider(req,resp.projectId).subscribe(res => {
      this.vcpmServ.inputChanged = false;
      this.vcpmServ.isProjectSaved = true;
      this.vcpmServ.vcpmSavedData = resp;
      this.vcpmServ.analysisName = resp.metadata.analysisName;
      this.onModalBtnSelection.emit(flag);
      if(this.btnText === this.translateService.instant('create.title')){
        this.whitneyService.isHomeClick = true;
        this.router.navigate([`/results/${resp.projectId}`])
      }
    },error => {
      this.alertService.error(this.translateService.instant('apiFailErrorMsg.title'),false)
    });
  }
  checkValue(){
    if(this.opportunityIDs.length === 0){
      this.getSelectedUserValue(this.newProjectForm.get('sfdcId').value,false);
    }
  }
  selChange(e: any){
    this.getSelectedUserValue(e.model,false);
  }
}
