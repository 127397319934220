<clr-modal [(clrModalOpen)]="cloneProjectModal" [clrModalClosable]="false" [clrModalSize]="'md'">
    <h3 class="modal-title">{{'cloneAnalysistooltip.title' | translate}} 
        <span class="close-icon">
            <clr-icon shape="times" (click)="modalHandler()"></clr-icon>
        </span>
    </h3>
    <div class="modal-body">
        {{'cloneMsg.title' | translate}} <strong>{{cloneProjName}}</strong> ?
    </div>
    <div class="modal-footer">
        <button class="btn btn-outline" (click)="modalHandler()">{{'cancel.title'| translate}}</button>
        <button class="btn btn-primary" (click)="cloneProject()">{{'confirm.title' | translate}}</button>
    </div>
</clr-modal>
<alert></alert>