import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentsService {
  private configuration = '${APP_CONFIG}';
  private env: any;
  private nodeEnvironment: string;
  selectedLang: string;

  constructor() {
    this.nodeEnvironment =
      this.configuration === '' || this.configuration.startsWith('$')
        ? ''
        : `.${this.configuration}`;
    this.env = require('../../../environments/environment' + this.nodeEnvironment);
  }

  get config() {
    return this.env.environment;
  }

  get isDevMode(): boolean {
    return this.nodeEnvironment === '';
  }

  get isTestingMode(): boolean {
    return this.nodeEnvironment === '.qa';
  }

  get isStgMode(): boolean {
    return this.nodeEnvironment === '.verify';
  }

  get isProdMode(): boolean {
    return this.nodeEnvironment === '.prod';
  }

  get instanceName(): string {
    return this.isProdMode
      ? 'production'
      : this.isStgMode
      ? 'staging'
      : this.isTestingMode
      ? 'testing'
      : 'local-testing';
  }
}
