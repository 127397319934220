import { Injectable, Injector } from '@angular/core';

import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { CacheService } from '@core/services/cache.service';
import { Observable, throwError } from 'rxjs';
import { shareReplay, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CacheInterceptor implements HttpInterceptor {
  constructor(private cache: CacheService, private injector: Injector) {}
  static readonly requestsNeedCache: string[] = [
    '/v3/api/programs?state=staged',
  ];

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      req.method === 'GET' &&
      CacheInterceptor.requestsNeedCache.some(x => req.urlWithParams.includes(x))
    ) {
      const cachedResponse = this.cache.get(req.urlWithParams);
      if (cachedResponse) {
        return cachedResponse;
      }

      this.cache.put(
        req.urlWithParams,
        next.handle(req).pipe(
          shareReplay(1),
          catchError(err => {
            this.cache.delete(req.urlWithParams);
            return throwError(err);
          }),
        ),
      );
      return this.cache.get(req.urlWithParams);
    } else {
      return next.handle(req);
    }
  }
}
