import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClrLoadingState } from '@clr/angular';
import { WhitneyService } from '@core/services/whitney.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-download-dialog',
  templateUrl: './download-dialog.component.html',
  styleUrls: ['./download-dialog.component.scss']
})
export class DownloadDialogComponent implements OnInit {
  @Input() openModal: boolean;
  @Output() openModalChange: EventEmitter<any> = new EventEmitter();
  @Output() startDownload: EventEmitter<boolean> = new EventEmitter<boolean>();

  showEmailInput: boolean = true;
  useremail: string;
  companyName: string;
  verificationCode: string;
  resendTime: number;
  counter: Subscription;
  validated: boolean;
  errorMsg: string;
  validateBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;

  constructor(private whitneyService: WhitneyService,private translateService: TranslateService) { }

  ngOnInit(): void {
  }

  getOTP(): void {
    this.whitneyService.getOTP(this.useremail).subscribe();
    this.showEmailInput = false;
    this.resendTime = 60;
    this.counter = timer(0, 1000).subscribe(() => {
      --this.resendTime;
      if (this.resendTime == 0) {
        this.counter.unsubscribe();
      }
    });
  }

  onCodeChanged(code: string) {
    this.errorMsg = undefined;
    this.verificationCode = code;
  }

  verfiyOTP() {
    this.validateBtnState = ClrLoadingState.LOADING;
    this.whitneyService.verfiyOTP(this.useremail, this.verificationCode, this.companyName).subscribe(
      _ => {
        this.validateBtnState = ClrLoadingState.LOADING;
        this.validated = true;
        this.startDownload.next(true);
      },
      err => {
        this.validateBtnState = ClrLoadingState.ERROR;
        this.errorMsg = this.translateService.instant('invalidPassCode.title');
        this.validated = false;
      }
    )
  }

  validateEmail() {
    if (!this.useremail || !this.useremail.includes('@')) {
      return false;
    }
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(this.useremail).toLowerCase());
  }

  onClose(event) {
    this.openModalChange.emit(false);
    this.useremail = undefined;
    this.companyName = undefined;
    this.verificationCode = undefined;
    this.showEmailInput = true;
    this.errorMsg = undefined;
    this.validated = false;
  }
}
