import { Injectable } from '@angular/core';

import { HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  cache: Map<string, Observable<HttpEvent<any>>> = new Map();

  /**
   * Returns a cached response, if any, or null if not present.
   */
  get(reqUrl: string): Observable<HttpEvent<any>> | null {
    return this.cache.get(reqUrl);
  }

  /**
   * Adds or updates the response in the cache.
   */
  put(reqUrl: string, resp: Observable<HttpEvent<any>>): void {
    this.cache.set(reqUrl, resp);
  }

  /**
   * Remove the response in the cache.
   */
  delete(reqUrl: string): void {
    this.cache.delete(reqUrl);
  }
}
