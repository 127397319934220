export interface VCPMResponse {
    metadata: Metadata,
    data: Data,
    active:boolean,
    createdBy:string,
    createdDate:string,
    currentOwner:string,
    isarchived:string,
    lastUpdate:string,
    locked:boolean,
    lockedBy:string,
    primaryVersion:boolean,
    projectId:number,
    sharingData:string,
    updatedBy:string,
    version:number
}
export interface Metadata {
    analysisName: string,
    companyName: string,
    testAnalysis: string,
    sfdcId: string,
    tags: string[],
    description: string,
    opportunity: string,
    locale: string
}
export interface Data {
    inputData: InputData[];
}
export interface InputData {
    id: number,
    currentAverage:string,
    goalAverage:string,
    gap:string,
    gapPercent:string,
    details:Details[]
}
export interface Details{
    id: number,
    goalVal:string,
    currentVal:string,
}