import { Component, forwardRef, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@core/services/authentication.service';
import { EnvironmentsService } from '@core/services/environments.service';
import { TranslateService } from '@ngx-translate/core';
import { Angulartics2Matomo } from 'angulartics2';

declare var telemetryService: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  constructor(
    public router: Router,private translateService: TranslateService, private angulartics: Angulartics2Matomo,
    @Inject(forwardRef(() => EnvironmentsService)) private envService: EnvironmentsService,
    private authService: AuthenticationService
  ) {
    this.translateService.use(localStorage.getItem('vmccma-lang') ? localStorage.getItem('vmccma-lang') : 'en');
    // Integrate with Appfx-Telemetry
    telemetryService.init(
      "vmcam-sc.v1" /*collectorId*/, 
      "lumos_analytics" /*table*/,
      this.envService.config.production /*isProduction*/
    );
    telemetryService.setUsername(this.authService.userEmail());
    this.angulartics.startTracking();
  // Analytics end
  }
}
