import { Injectable, forwardRef, Inject} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, observable } from 'rxjs';
import { map, debounce } from 'rxjs/operators';
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from '@angular/router';

import { EnvironmentsService } from './environments.service';
import { User } from '@core/models/user';
import { WhitneyService } from './whitney.service';

const helper = new JwtHelperService();

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    private tokenExpirationTimer: any;
    constructor(private http: HttpClient, private router: Router,
                @Inject(forwardRef(() => EnvironmentsService)) private envService: EnvironmentsService,
                private whitneyService: WhitneyService) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        this.autoRefreshToken();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }
    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        return true;
    }

    logoutFromLocal() {
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }

    isActiveUser() {
        return localStorage.getItem('currentUser') && this.currentUser;
    }

    userEmail() {
        return this.currentUser ? this.currentUserValue?.email : '';
    }
    autoRefreshToken(): void {
        if (this.isActiveUser()) {
          const expirationDate: Date | null = helper.getTokenExpirationDate(
            this.currentUserValue.access_token
          );
          if (expirationDate) {
            // 10 seconds reserved for requesting new tokens
            const expirationMilliseconds =
              new Date(expirationDate).getTime() - new Date().getTime() - 30 * 1000;
            if (this.tokenExpirationTimer) {
              clearTimeout(this.tokenExpirationTimer);
            }
            this.tokenExpirationTimer = setTimeout(() => {
              this.refreshToken();
            }, Math.max(expirationMilliseconds, 0));
          }
    
        }
    }
    refreshToken(){
        let refreshTokenObj = {
            "refresh_token": this.currentUserValue.refresh_token
        }
        this.whitneyService.getAccessTokenFromRefreshToken(refreshTokenObj,"custom")
        .subscribe(response => {
            let user = new User();
            if (response && response.access_token) {
                // Build up a user
                user.access_token  = response.access_token;
                user.email  = this.currentUserValue.email;
                user.refresh_token = response.refresh_token
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
                this.autoRefreshToken();
            }
        });
    }
}