import { Component, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from '@core/services/alert.service';
import { VCPMService } from '@core/services/vcpm.service';
import { WhitneyService } from '@core/services/whitney.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-saved-projects',
  templateUrl: './saved-projects.component.html',
  styleUrls: ['./saved-projects.component.scss']
})
export class SavedProjectsComponent implements OnInit {
  searchText: any;
  cloneProjectModal: boolean;
  savedProjectsSearched: any;
  savedConfigs: any;
  deleteProject: boolean;
  shareProject: boolean;
  updateProject: boolean;
  editAnalysistooltip: any;
  newAnalysisBtnState:boolean
  formName: any;
  btnText: any;

  constructor(public whitneyService: WhitneyService,public router: Router,private renderer: Renderer2,
    public vcpmSer: VCPMService,private alertService: AlertService,private translateService: TranslateService) { }

  ngOnInit(): void {
    this.vcpmSer.inputChanged = false;
    this.vcpmSer.redirectPage = '';
    this.getProjectsMetadata();
    this.searchText = new UntypedFormControl('');
    this.searchText.valueChanges.subscribe((value) => {
      console.log(value);
      let searchString = value.toString().toLowerCase().trim();
      if (searchString) {
        this.savedProjectsSearched = this.savedConfigs.filter(data => {
          console.log(data)
          return ((data.metadata.analysisName && data.metadata.analysisName.toLowerCase().indexOf(searchString) >= 0)
          || (data.projectId && data.projectId.toString().toLowerCase().indexOf(searchString) >= 0)
          || (data.metadata.companyName && data.metadata.companyName.toString().toLowerCase().indexOf(searchString) >= 0)
          || (data.metadata.locale && data.metadata.locale.toString().toLowerCase().indexOf(searchString) >= 0)
          || (data.lastUpdate && data.lastUpdate.toString().toLowerCase().indexOf(searchString) >= 0)
          || (data.metadata.description && data.metadata.description.toString().toLowerCase().indexOf(searchString) >= 0)
          || (data.metadata.solName && data.metadata.solName.toString().toLowerCase().indexOf(searchString) >= 0)
          || (data.metadata.testAnalysis && data.metadata.testAnalysis.toString().toLowerCase().indexOf(searchString) >= 0)
          || (data.metadata.opportunity !== undefined && data.metadata.opportunity.id && data.metadata.opportunity.id.toString().toLowerCase().indexOf(searchString) >= 0)
          || (data.metadata.sfdcId !== "" && data.metadata.sfdcId.fullName && data.metadata.sfdcId.fullName.toString().toLowerCase().indexOf(searchString) >= 0)
          || (data.metadata.tags && data.metadata.tags.toString().toLowerCase().indexOf(searchString) >= 0));
        });
      }else {
        this.savedProjectsSearched = this.savedConfigs;
      }
    });
  }
  
  getProjectsMetadata(){
    this.vcpmSer.getProjectsMetadata().subscribe((resp:any) => {
      if(resp !== null && resp !== undefined){
        this.savedConfigs = resp.content;
        this.savedProjectsSearched = this.savedConfigs;
        for(let i of this.savedProjectsSearched){
          i.metadata.locale = this.getLanguage(i.metadata.locale)
        }
      }else{
        this.savedConfigs = [];
        this.savedProjectsSearched = this.savedConfigs;
      }
    },error => {
      this.alertService.error(this.translateService.instant('apiFailErrorMsg.title'),false)
    });
  }

  onEditProject(solution){
    this.whitneyService.questions = [];
    localStorage.setItem('vmccma-lang', this.getLocale(solution.metadata.locale));
    this.vcpmSer.getAnalysisDetails(solution.projectId).subscribe((resp:any) => {
      this.vcpmSer.vcpmSavedData = resp;
      this.vcpmSer.inputChanged = false;
      location.replace(window.location.origin + `/tools/vmccma/results/${solution.projectId}`);
    },error => {
      this.alertService.error(this.translateService.instant('apiFailErrorMsg.title'),false)
    })
  }
  
  setFocusToInput() {
    this.renderer.selectRootElement('#searchConfig', true).focus();
  }
  truncateLongString(str,len){
    if (str != null && str !== undefined && str !== '' && str.length > len) {
      const strEllipses = str.substr(0, len);
      return strEllipses + '...';
    } else {
      return str;
    }
  }
  cloneProject(solution) {
    this.vcpmSer.getAnalysisDetails(solution.projectId).subscribe((resp:any) => {
      this.vcpmSer.vcpmSavedData = resp;
      this.cloneProjectModal = true;
    },error => {
      this.alertService.error(this.translateService.instant('apiFailErrorMsg.title'),false)
    });
  }
  closeCloneProject(event){
    this.cloneProjectModal = false;
    if (event.clone) {
      this.alertService.success(this.translateService.instant('cloneSuccessMsg.title'), true);
      this.getProjectsMetadata();
    }
  }
  removeProject(solution) {
    this.deleteProject = true;
    this.vcpmSer.vcpmSavedData = solution;
  }
  closeDeleteProject(event: {deleted: boolean}) {
    this.deleteProject = false;
    if (event.deleted) {
      this.alertService.success(this.translateService.instant('deleteSuccessMsg.title'), true);
      this.vcpmSer.vcpmSavedData = undefined;
      this.getProjectsMetadata();
    }
  }
  onShareProject(solution){
    this.shareProject = true;
    this.vcpmSer.vcpmSavedData = solution;
  }
  onEdit(solution) {
    this.formName = this.translateService.instant('updateAnalysis.title');
    this.btnText = this.translateService.instant('update.title');
    this.updateProject = true;
    this.vcpmSer.vcpmSavedData = solution;
  }
  closeUpdateProjectModal(event) {
    this.updateProject = false;
    if (event) {
      this.alertService.success(this.translateService.instant('updateSuccessMsg.title'), true);
      this.getProjectsMetadata();
    }
  }
  closeShareProject(event:{shared: boolean}){
    this.shareProject = false;
    if(event.shared){
      this.alertService.success(this.translateService.instant('shareSuccessMsg.title'), true);
      this.getProjectsMetadata();
    }
  }
  onNewAnalysisClick(value){
    this.vcpmSer.vcpmSavedData = undefined;
    this.whitneyService.questionsResp = undefined;
    this.newAnalysisBtnState = value;
    this.btnText = this.translateService.instant('create.title');
    this.formName = this.translateService.instant('createAnalysis.title');
    /*if(!value && this.vcpmSer.isProjectSaved){
      this.vcpmSer.isProjectSaved = false;
      this.alertService.success(this.translateService.instant('createSuccessMsg.title'),true);
      //this.getProjectsMetadata();
    }*/
  }
  getLanguage(locale){
    if(locale === 'zh'){
      return "Chinese"
    }else if(locale === 'ja'){
      return "Japanese"
    }else if(locale === 'es'){
      return "Spanish"
    }else{
      return "English"
    }
  }
  getLocale(locale){
    if(locale === 'Chinese'){
      return "zh"
    }else if(locale === 'Japanese'){
      return "ja"
    }else if(locale === 'Spanish'){
      return "es"
    }else{
      return "en"
    }
  }
}
