import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '@core/services/authentication.service';
import { JwtHelperService } from "@auth0/angular-jwt";
import { WhitneyService } from '@core/services/whitney.service';
import { User } from '@core/models/user';
let helper = new JwtHelperService();
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    
    constructor(private authenticationService: AuthenticationService,private whitneyservice: WhitneyService) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser = this.authenticationService.currentUserValue;
        let tokenString = 'x-vmw-esp-client';
        if(currentUser !== null && (request.url.includes('/vcpm/'))){
            return next.handle(this.handleRequest(request,currentUser,tokenString));
        }else{
            return next.handle(this.handleRequest(request,currentUser,tokenString));
        }
        
            
    }
    handleRequest(request,currentUser,tokenString){
        if(request.url.includes('/vcpm/'))  {
            request = request.clone({
                setHeaders: { 
                    [tokenString]: `${currentUser.access_token}`,
                    'x-client-auth-provider' :`custom`    
                }
            });
        }
        return request;
    }
}