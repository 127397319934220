import {Component, forwardRef, Inject, OnInit, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Observable, timer, NEVER, BehaviorSubject } from 'rxjs';
import { map, tap, takeWhile, startWith, switchMap } from 'rxjs/operators';
import { AuthenticationService } from '@core/services/authentication.service';
import { WhitneyService } from '@core/services/whitney.service';
import { User } from '@core/models/user';
import { AlertMessage, AlertType } from 'src/app/helpers/alert.type';
import { TranslateService } from '@ngx-translate/core';
import { EnvironmentsService } from '@core/services/environments.service';
import { VCPMService } from '@core/services/vcpm.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})

export class LoginComponent implements OnInit {
		@ViewChild('loginForm') public loginForm: NgForm;
		useremail: string = '';
		otp: number = null;
		loading = false;
		sentOTP = false;
		submitted = false;
		otpExpired = false;
		otpTimeDisplay = '';
		returnUrl: string;
		defaultTimeInMinutes: Number = 10;
		error = '';
		toggle$ = new BehaviorSubject(true);
		loginAlert: AlertMessage = {
			message: '',
			type: AlertType.INFO
		};

		constructor(
			private route: ActivatedRoute,
			private router: Router,
			public authenticationService: AuthenticationService,
			private whitneyService: WhitneyService,
			private translateService: TranslateService,
			@Inject(forwardRef(() => EnvironmentsService)) private env: EnvironmentsService,
			private vcpmser : VCPMService
		) { 
			// redirect to home if already logged in
			if (this.authenticationService.isActiveUser()) { 
					this.router.navigate(['/results']);
			}
		}

		ngOnInit() {
		}

		// convenience getter for easy access to form fields
		get f() { return this.loginForm.controls; }

		sendOTP() {
			if (this.loginForm.invalid) {
				return;
			}
			let email = this.loginForm.value.email;
			this.getOneTimePwd(email);
		}

		resendOTP() {
			this.loginAlert = {
				message: '',
				type: AlertType.INFO
			};
			this.otp = null;
			this.getOneTimePwd(this.useremail);
		}

		getOneTimePwd(email){
			this.whitneyService.getOneTimepwd(email).subscribe(response => {
				if(response) {
					this.sentOTP = true;
					//this.SubmitTxt = this.loginBtnTxt;
					let msg = this.translateService.instant('OTPSuccessMsg.title') + ' ' + email + ' ' + this.translateService.instant('validtimer.title');
					this.loginAlert = {
						message: msg,
						type: AlertType.SUCCESS
					};
					setTimeout(()=>{    
						this.countDown(this.defaultTimeInMinutes);
					}, 200);
				} else {
				}
			}, error => {
				this.loginAlert = {
					message: this.translateService.instant('apiFailErrorMsg.title'),
					type: AlertType.DANGER
				}
			});
		}
		
		login() {
			// stop here if form is invalid
			if (this.loginForm.invalid) {
				return;
			}
			this.submitted = true
			this.loading = true;
			let reqObj = {
				"userEmail": this.useremail,
				"otp": this.otp,
				"clientId": `${this.env.config.clientId}`
			  }
			  this.whitneyService.getAccessAndRefreshToken(reqObj).subscribe(response => {
				if (response && response.access_token) {
					let user = new User();
					// Build up a user
					user.access_token  = response.access_token;
					user.email  = this.useremail;
					user.refresh_token = response.refresh_token
			
					// store user details and jwt token in local storage to keep user logged in between page refreshes
					localStorage.setItem('currentUser', JSON.stringify(user));
					this.authenticationService.currentUserSubject.next(user);
					this.authenticationService.autoRefreshToken();
					(<any>window)._paq.push(['trackEvent', 'Login Type', 'User Login' + "(User Email:" + this.useremail + ")"]);
					if(this.vcpmser.redirectPage !== ''){
						this.router.navigate([this.vcpmser.redirectPage])
					}else {
						this.router.navigate(['/results'])
					}
				}
			  },error => {
				if(error.status == 400) {
					this.loginAlert = {
						message: this.translateService.instant('invalidPasscode.title'),
						type: AlertType.DANGER
					}
				} else {
					this.loginAlert = {
						message: this.translateService.instant('apiFailErrorMsg.title'),
						type: AlertType.DANGER
					}
				}
				this.loading = false;
			});
		}

		countDown(minute) {
		
			if(this.toggle$.value) {
				this.toggle$.next(!this.toggle$.value);
			}
	
			this.toggle$ = new BehaviorSubject(true);
			const toggle$ = this.toggle$;
	
			const K = 1000;
			const INTERVAL = K;
			const TIME = minute * K * 60;
	
			let current: number;
			let time = TIME;
	
			const toMinutesDisplay = (ms: number) => Math.floor(ms / K / 60);
			const toSecondsDisplay = (ms: number) => Math.floor(ms / K) % 60;
	
			const toSecondsDisplayString = (ms: number) => {
				const seconds = toSecondsDisplay(ms);
				return seconds < 10 ? `0${seconds}` : seconds.toString();
			};
	
			const toMinutesDisplayString = (ms: number) => {
				const minutes = toMinutesDisplay(ms);
				return minutes < 10 ? `0${minutes}` : minutes.toString();
			};
	
			const currentSeconds = () => time / INTERVAL;
			const toMs = (t: number) => t * INTERVAL
			const toRemainingSeconds = (t: number) => currentSeconds() - t;
	
			const remainingSeconds$ = toggle$.pipe(
				switchMap((running: boolean) => (running ? timer(0, INTERVAL) : NEVER)),
				map(toRemainingSeconds),
				takeWhile(t => t >= 0),
			);
	
			const ms$ = remainingSeconds$.pipe(
				map(toMs),
				tap(t => current = t)
			);
	
			const minutes$ = ms$.pipe(
				map(toMinutesDisplayString),
				map(s => s.toString()),
				startWith(toMinutesDisplayString(time).toString())
			);
	
			const seconds$ = ms$.pipe(
				map(toSecondsDisplayString),
				startWith(toSecondsDisplayString(time).toString())
			);
	
			// update DOM
			const minutesElement = document.querySelector('#timer .minutes');
			const secondsElement = document.querySelector('#timer .seconds');
			const toggleElement = document.querySelector('#timer');
	
			this.updateDom(minutes$, minutesElement);
			this.updateDom(seconds$, secondsElement);
	
			remainingSeconds$.subscribe({
				complete: () => {
					this.loginAlert = {
						message: this.translateService.instant('OTPExpired.title'),
						type: AlertType.DANGER
					};
					this.otpExpired = true;
				}
			});
	
		}
	
		updateDom(source$: Observable<string>, element: Element) {
			source$.subscribe((value) => element.innerHTML = value);
		}

		refreshLogin() {
			location.reload();
		}
}
