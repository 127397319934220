<section id="input-search" class="sub-container searchPos">
    <div class="clr-row clr-align-items-center">
        <div class="clr-col-xs-12 clr-col-sm-12 clr-col-md-3 clr-col-lg-3"
            style="display: flex; position: relative; padding-left: .2rem;">
            <input clrInput type="search" [formControl]="searchText" placeholder="{{'search.title' | translate}}"
                id='searchConfig'>
            <cds-icon shape="search" size="18" (click)="setFocusToInput()" class="cursor search-icon-position">
            </cds-icon>
        </div>
        <div class="clr-col-xs-12 clr-col-sm-12 clr-col-md-9 clr-col-lg-9 text-align-right">
            <button class="btn btn-primary" (click)="onNewAnalysisClick(true)" id="newProject">
                {{'createAnalysis.title' | translate}}
            </button>
        </div>
    </div>
</section>
    <clr-datagrid class="analysisGrid">
    <clr-dg-column [clrDgField]="'projectId'" class="analysisIdwidth">
        <ng-container *clrDgHideableColumn="{hidden: false}">{{'analysisid.title' | translate}}</ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'metadata.analysisName'" class="width">
        <ng-container *clrDgHideableColumn="{hidden: false}">{{'analysisName.title' | translate}}</ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'metadata.companyName'">
        <ng-container *clrDgHideableColumn="{hidden: false}">{{'company.title' | translate}}</ng-container>
    </clr-dg-column>
    
    <clr-dg-column [clrDgField]="'metadata.testAnalysis'">
        <ng-container *clrDgHideableColumn="{hidden: false}">{{'testAnalysis.title' | translate}}</ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'metadata.sfdcId.fullName'" style="width:150px">
        <ng-container *clrDgHideableColumn="{hidden: true}">{{'partnid.title' | translate}}</ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'metadata.opportunity.id'" class="oppIdWidth">
        <ng-container *clrDgHideableColumn="{hidden: true}">{{'oppId.title'| translate}}</ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'metadata.tags'">
        <ng-container *clrDgHideableColumn="{hidden: true}">{{'tags.title' | translate}}</ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'metadata.locale'">
        <ng-container *clrDgHideableColumn="{hidden: true}">{{'locale.title' | translate}}</ng-container>
    </clr-dg-column>
    <clr-dg-column>
        <ng-container *clrDgHideableColumn="{hidden: false}">{{'lastUpdated.title' | translate}}</ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'metadata.description'" style="width:125px">
        <ng-container *clrDgHideableColumn="{hidden: false}">{{'desc.title' | translate}}</ng-container>
    </clr-dg-column>
    <clr-dg-column>
        <ng-container *clrDgHideableColumn="{hidden: false}">{{'actions.title' | translate}}</ng-container>
    </clr-dg-column>
    <clr-dg-placeholder>{{'noProject.title' | translate}}</clr-dg-placeholder>
    <clr-dg-row *clrDgItems="let config of savedProjectsSearched; let i = index" [clrDgItem]="config">
    <clr-dg-action-overflow>
        <button class="action-item" (click)="onEdit(config)">{{'editMetadata.title' | translate}}</button>
    </clr-dg-action-overflow>
    <clr-dg-cell class="analysisIdwidth">{{config.projectId}}</clr-dg-cell>
    <clr-dg-cell class="width">
        <div (click)="onEditProject(config)" class="editProj" style="cursor: pointer;">
            <span title="{{config.metadata.analysisName}}">{{truncateLongString(config.metadata.analysisName,15)}}</span>
        </div>
    </clr-dg-cell>
    <clr-dg-cell>{{config.metadata.companyName}}</clr-dg-cell>
    <clr-dg-cell>{{config.metadata.testAnalysis}}</clr-dg-cell>
    <clr-dg-cell *ngIf="config.metadata.sfdcId === ''">{{config.metadata.sfdcId}}</clr-dg-cell>
    <clr-dg-cell class="spanWrap" *ngIf="config.metadata.sfdcId !== ''" title="{{config.metadata.sfdcId.fullName}}">{{config.metadata.sfdcId.fullName}}</clr-dg-cell>
    <clr-dg-cell *ngIf="config.metadata.opportunity === ''">{{config.metadata.opportunity}}</clr-dg-cell>
    <clr-dg-cell  *ngIf="config.metadata.opportunity !== ''" style="width:114px">{{config.metadata.opportunity.id}}</clr-dg-cell>
    <clr-dg-cell>
        <ng-container *ngFor="let tag of config.metadata.tags; let k = index">
            <div class="label ml-10" title="{{tag}}">{{truncateLongString(tag,8)}}</div>
        </ng-container>
    </clr-dg-cell>
    <clr-dg-cell>{{config.metadata.locale}}</clr-dg-cell>
    <clr-dg-cell>{{config.lastUpdate | date:'mediumDate'}}</clr-dg-cell>
    <clr-dg-cell class="spanWrap" title="{{config.metadata.description}}">{{config.metadata.description}}</clr-dg-cell>
    <clr-dg-cell style="min-width:110px;">
        <span style="padding-right: 5px;cursor: pointer;" (click)="onEditProject(config)">
            <cds-icon shape="pencil" title="{{'editAnalysistooltip.title' | translate}}"></cds-icon>
        </span>
        <span style="padding-right: 5px;cursor: pointer;" (click)="cloneProject(config)">
            <cds-icon shape="clone" title="{{'cloneAnalysistooltip.title' | translate}}"></cds-icon>
        </span>
        <span style="padding-right: 5px;cursor: pointer;" (click)="onShareProject(config)">
            <cds-icon shape="share" title="{{'shareAnalysistooltip.title' | translate}}"></cds-icon>
        </span>
        <span style="padding-right: 5px;cursor: pointer;" (click)="removeProject(config)">
            <cds-icon shape="trash" title="{{'deleteAnalysistooltip.title' | translate}}"></cds-icon>
        </span>
    </clr-dg-cell>
</clr-dg-row>
<clr-dg-footer>
    <clr-dg-pagination #pagination [clrDgPageSize]="10">
        <clr-dg-page-size [clrPageSizeOptions]="[5,10,20,30, 100, 1000]">{{'analysisPerPage.title' | translate}}</clr-dg-page-size>
        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
        of {{pagination.totalItems}} {{'analyis.title' | translate}}
    </clr-dg-pagination>
</clr-dg-footer>
    </clr-datagrid>
    <alert></alert>
    <app-new-project *ngIf="newAnalysisBtnState" [modalState]="newAnalysisBtnState" (onModalBtnSelection)="onNewAnalysisClick($event)" [formName]="formName" [btnText]="btnText"></app-new-project>
    <!--<edit-project *ngIf="editProjectModal" [editProjectModal]="editProjectModal" (closeEditProject)="closeEditProject($event)"></edit-project>-->
    <delete-project *ngIf="deleteProject" [deleteProject]="deleteProject" (closeDeleteProject)="closeDeleteProject($event)"></delete-project>
    <clone-project *ngIf="cloneProjectModal" [cloneProjectModal]="cloneProjectModal" [projectList] = "savedConfigs" (closeCloneProject)="closeCloneProject($event)" ></clone-project>
    <share-project *ngIf="shareProject" [shareProject]="shareProject" (closeShareProject)="closeShareProject($event)"></share-project>
    <app-new-project *ngIf="updateProject" [modalState]="updateProject" (onModalBtnSelection)="closeUpdateProjectModal($event)" [formName]="formName" [btnText]="btnText"></app-new-project>