import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from '@shared/components/login/login.component';
import { NotFoundPageComponent } from '@shared/components/not-found-page/not-found-page.component';
import { QuestionsComponent } from './questions/questions.component';
import { ResultsComponent } from './results/results.component';
import { SavedProjectsComponent } from '@shared/saved-projects/saved-projects.component';
import { AuthGuard } from './helpers/auth.guard';
import { ReleaseNotesComponent } from '@shared/components/release-notes/release-notes.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'results',
    pathMatch: 'full',
  },
  {
  path: 'results/:projectId',
  component: ResultsComponent,
  pathMatch: 'full',
  },
  {
    path: 'results',
    component: ResultsComponent,
    pathMatch: 'full',
  },
  {
    path: 'questions/:projectId/:area',
    component: QuestionsComponent,
    pathMatch: 'full',
  },
  {
    path: 'questions/:area',
    component: QuestionsComponent,
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    pathMatch: 'full',
  },
  {
    path: 'saved-projects',
    component: SavedProjectsComponent,
    pathMatch: 'full',
    canActivate: [ AuthGuard ]
  },
  {
    path: 'release-notes',
    component: ReleaseNotesComponent,
    pathMatch: 'full',
    canActivate: [ AuthGuard ]
  },
  {
    path: '**',
    data: {
      title: 'Private Cloud Maturity Model Assessment 404 Page',
    },
    component: NotFoundPageComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {}),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
