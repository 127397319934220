<div class="main-container">
  <app-header></app-header>
  <div class="content-container content-dark">
    <div class="content-area">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="footer-main">
    <app-footer></app-footer>
  </div>
</div>
