import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { VCPMService } from '@core/services/vcpm.service';

@Component({
  selector: 'alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {
  @Input() alertProject: boolean;
  @Input() reDirectTo;
  @Output() closeAlertDialog = new EventEmitter();
  constructor(private router: Router,private vcpmService: VCPMService) { }

  ngOnInit(): void {
  }

  onYesClick(){
    if(this.reDirectTo === '/results'){
      this.closeAlertDialog.emit(true);
    }else if(this.reDirectTo === 'lang'){
      location.reload();
    }else{
      this.vcpmService.inputChanged = false;
      this.closeAlertDialog.emit(false);
      this.router.navigate([this.reDirectTo]);
    }
  }
  modalHandler(){
    this.closeAlertDialog.emit(false);
  }
}


