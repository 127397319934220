<div *ngIf="!loading && areaQuestions">
    <div class="clr-row clr-col-12 nav-bar summary-wd" [ngClass]="{'no-banner': env.isProdMode}">
        <div>
            <a *ngIf="vcpmServ.vcpmSavedData == undefined" (click) = "onQuestionClick()" style="color: #56BBF0;cursor: pointer;">{{ 'summary.title' | translate }}</a>
            <a *ngIf="vcpmServ.vcpmSavedData !== undefined" (click) = "onQuestionClick()" style="color: #56BBF0;cursor: pointer;">{{projectNameWithId}}</a>
                <span class="breadcrumb-arrow">  > </span><span>{{
                areaQuestions.description }}</span>
        </div>
        <div class="download-btn">
            <button class="btn btn-primary" (click)="onSaveBtnClick(true)">{{'save.title' | translate}}</button>
        </div>
    </div>
    
    <div>
        <p class="help-text">
            <span class="info-label">{{ 'avg.title' | translate }}:</span> <span>{{ 'current.title' | translate }}: {{ areaQuestions.currentAverage }}</span><span>{{ 'goal.title' | translate }}: {{ areaQuestions.goalAverage }}</span>
            <span class="info-label">|</span>
            <span class="info-label">{{ 'target.title' | translate }}:</span><span>{{ areaQuestions.audience }}</span>
        </p>
        <clr-datagrid class="questions-grid">
            <clr-dg-column style="width: 25rem">{{areaQuestions.description }}</clr-dg-column>
            <clr-dg-column class="align-center" style="width: 8rem">{{ 'current.title' | translate }}</clr-dg-column>
            <clr-dg-column class="align-center" style="width: 8rem">{{ 'goal.title' | translate }}</clr-dg-column>
            <clr-dg-column style="width: 43rem">{{ 'desc.title' | translate }}</clr-dg-column>

            <clr-dg-row *ngFor="let detail of areaQuestions.details">
                <clr-dg-cell>{{detail.series}}</clr-dg-cell>
                <clr-dg-row-detail [clrIfExpanded]='true' class="question-detail">
                    <clr-dg-cell>{{detail.shortDesc}}</clr-dg-cell>
                    <clr-dg-cell>
                        <select class="select-point" clrSelect name="options"
                            (change)="onCurrentChange($event, detail)">
                            <option *ngFor="let value of detail.currentVal.supported" [value]="value"
                                [selected]="value == (detail.currentVal.selected || detail.currentVal.default)">{{ value
                                }} - {{ getScoreDesc(value) }}</option>
                        </select>
                    </clr-dg-cell>
                    <clr-dg-cell>
                        <select class="select-point" clrSelect name="options" (change)="onGoalChange($event, detail)">
                            <option *ngFor="let value of detail.goalVal.supported" [value]="value"
                                [selected]="value == (detail.goalVal.selected || detail.goalVal.default)">{{ value }} - {{ getScoreDesc(value) }}
                            </option>
                        </select>
                    </clr-dg-cell>
                    <clr-dg-cell class="answer-desc">
                        <ol>
                            <li *ngFor="let info of detail.assessmentInfo">{{ info.description }}</li>
                        </ol>
                    </clr-dg-cell>
                </clr-dg-row-detail>
            </clr-dg-row>
        </clr-datagrid>
    </div>
</div>
<div *ngIf="!loading && errorMsg">
    {{ 'errorMsg.title' | translate }}
</div>
<div *ngIf="loading" class="loading-icon">
    <span class="spinner"></span>
</div>
<app-new-project *ngIf="saveProjectModal && (vcpmServ.vcpmSavedData == undefined)" [modalState]="saveProjectModal" [formName]="formName" [btnText]="btnText"
(onModalBtnSelection)="closeSaveProject($event)"></app-new-project>
<alert></alert>    