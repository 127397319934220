import { HttpClient } from "@angular/common/http";
import { Injectable, forwardRef, Inject} from '@angular/core';
import { AreaQuestions } from "@core/models/questions";
import { EMPTY, Observable, of } from "rxjs";
import { map, tap } from "rxjs/operators";
import { EnvironmentsService } from "./environments.service";
import { PartnerSearchrequest } from "@core/models/partnerSearchRequest";
import { PartnerOppurtunitiesRequest } from "@core/models/partnerOppurtunitiesRequest";

@Injectable({
  providedIn: 'root',
})
export class WhitneyService {
  baseUrl: string;
  questions: AreaQuestions[];
  validatedUser: { useremail: string; companyName?: string };
  questionsResp : AreaQuestions[];
  isHomeClick: boolean;
  sfdcUrl: string;
  questionsCopy:AreaQuestions[]

  constructor(private http: HttpClient,
              @Inject(forwardRef(() => EnvironmentsService)) private env: EnvironmentsService) {
    this.baseUrl = `${this.env.config.wpConfigServiceUrl}`;
    this.sfdcUrl = `${this.env.config.sfdcUrl}`
  }

  public getQuestions(): Observable<AreaQuestions[]> {
    if(this.isHomeClick){
      this.questions = [];
      this.isHomeClick = false;
    }
    if (this.questions && this.questions.length > 0) {
      return of(this.questions);
    }
    return this.http.get<AreaQuestions[]>(this.baseUrl + `/configurationapi/v1/vmccma/categories/supportedvalues?locale=${localStorage.getItem('vmccma-lang') ? localStorage.getItem('vmccma-lang') : 'en'}`).pipe(
      map(result => {
        this.questions = result;
        return result;
      })
    );
  }

  public getQuestionsByArea(area: string): Observable<AreaQuestions> {
    return this.getQuestions().pipe(
      map(
        results => {
          return results.find(x => x.shortDesc === area);
        }
      )
    );
  }

  public refreshAverage() {
    this.questions.forEach(q => this.refreshAverageByArea(q.id));
    const maxGap = Math.max.apply(Math, this.questions.map(q => q.gap));
    const minGap = Math.min.apply(Math, this.questions.map(q => q.gap));
    const range = maxGap - minGap;
    this.questions.forEach(q => q['gapPercent'] = (Number(q.gap) - minGap) * 100 / range);
    this.questionsResp = this.questions;
  }

  public refreshAverageByArea(areaId: number) {
    const question = this.questions.find(x => x.id === areaId);
    if (question) {
      let currentSum = 0;
      let goalSum = 0
      question.details.forEach(ela => {
        currentSum += ela.currentVal.selected || ela.currentVal.default;
        goalSum += ela.goalVal.selected || ela.goalVal.default;
      })
      question['currentAverage'] = Number(currentSum / question.details.length).toFixed(2);
      question['goalAverage'] = Number(goalSum / question.details.length).toFixed(2);
      question['gap'] = (Number(question.goalAverage) - Number(question.currentAverage)).toFixed(2);
      this.questionsResp = this.questions;
    }
  }

  public getOTP(email: string): Observable<AreaQuestions[]> {
    return this.http.get<any>(this.baseUrl + `/configurationapi/v1/vmccma/otp?userEmail=${email}`);
  }

  public hasValidatedEmail(): boolean {
    return !!this.validatedUser;
  }

  public getUserInfo(): any {
    return this.validatedUser;
  }

  public verfiyOTP(email: string, code: string, companyName?: string): Observable<AreaQuestions[]> {
    return this.http.get<any>(this.baseUrl + `/configurationapi/v1/vmccma/otp/verify?userEmail=${email}&otpNumber=${code}`).pipe(
      tap(result => {
        if (result.status == 200) {
          this.validatedUser = { useremail: email, companyName };
        }
      })
    );
  }
  getSFDCCustomerDetails(id): Observable<any> {
    if (id !== null && id !== '') {
        return this.http.post<any>(this.sfdcUrl +`/v1/customers/search`,id)
    } else { return EMPTY; }
  }
  getPartnerId(partnerSearchRequest: PartnerSearchrequest){
    return this.http.post<any>(this.sfdcUrl +`/v1/partners/search`,partnerSearchRequest)
  }
  getOpportunityId(opportunityID): Observable<any> {
    return this.http.post<any>(this.sfdcUrl +`/v1/customers`,opportunityID)
  }
  getOppurtuniesForPartnerId(partnerOppurtunitiesRequest:PartnerOppurtunitiesRequest){
    return this.http.post<any>(`${this.sfdcUrl}/v1/partners/opportunities`,partnerOppurtunitiesRequest)
  }
  getOneTimepwd(email){
    return this.http.get<any>(this.baseUrl +`/configurationapi/v1/vmccma/auth/otp?userEmail=${email}&purpose=authentication`)
  }
  getAccessAndRefreshToken(reqObj){
    return this.http.post<any>(this.baseUrl +`/configurationapi/v1/vmccma/auth/otp/verify/token`,reqObj);
  }
  getAccessTokenFromRefreshToken(refreshTokenObj,token_type) : Observable<any>{
    return this.http.post<any>(this.baseUrl +`/configurationapi/v1/vmccma/auth/otp/token/refresh?token_type=${token_type}`,refreshTokenObj)
  }
  stringToArrayConv(tags: string): Array<string> {
    if (tags && tags.length > 0) {
        if (!Array.isArray(tags)) {
            return (tags.split(',')).map(element => {
                if (element) {
                    let str = element.trim();
                    return str;
                }
            }).filter(val => {
                if (val) {
                    return val;
                }
            });
        } else {
            return tags;
        }
    }
  }
  
  prepareInputData(){
    let reqobj = [];
    for(let i in this.questionsResp){
    let details = [];
      let id = Number(i)+1;
      let obj = {
        id:id,
        currentAverage:this.questionsResp[i].currentAverage,
        goalAverage:this.questionsResp[i].goalAverage,
        gap:this.questionsResp[i].gap,
        gapPercent:this.questionsResp[i]['gapPercent'],
      }
      for(let j in this.questionsResp[i].details){
        let goal;
        let curval;
        if(this.questionsResp[i].details[j].goalVal.selected !== undefined){
          goal = this.questionsResp[i].details[j].goalVal.selected
        }else if(this.questionsResp[i].details[j].goalVal.default !== undefined){
          goal = this.questionsResp[i].details[j].goalVal.default
        }
        if(this.questionsResp[i].details[j].currentVal.selected !== undefined){
          curval = this.questionsResp[i].details[j].currentVal.selected
        }else if(this.questionsResp[i].details[j].currentVal.default !== undefined){
          curval = this.questionsResp[i].details[j].currentVal.default
        }
        let id = Number(j)+1;
        let obj1 = {
          id:id,
          goalVal:goal,
          currentVal:curval,
        }
        details.push(obj1);
      }
      obj['details'] = details
      reqobj.push(obj);
    }
    let inputData = {
      "inputData" : reqobj
    }
    return inputData;
  }
}
