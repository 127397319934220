<clr-modal [clrModalOpen]="openModal" (clrModalOpenChange)="onClose($event)">
    <h3 class="modal-title">{{ 'emailVerification.title' | translate}}</h3>
    <div class="modal-body dialog">
        <ng-container *ngIf="showEmailInput && !validated">
            <p>{{ 'validEmail.title' | translate}}</p>
            <clr-input-container>
                <label>{{ 'email.title' | translate}}<span class="asterisk">*</span>: </label>
                <input autocomplete="off" clrInput name="email" [(ngModel)]="useremail" />
            </clr-input-container>
            <clr-input-container>
                <label>{{'company.title' | translate}}: </label>
                <input autocomplete="off" clrInput name="company" [(ngModel)]="companyName" />
            </clr-input-container>
        </ng-container>
        <ng-container *ngIf="!showEmailInput && !validated">
            <p style="margin-bottom: 10px" *ngIf="translateService.currentLang === 'es' || translateService.currentLang === 'en'">{{'enterCode.title' | translate}} {{useremail}}</p>
            <p style="margin-bottom: 10px" *ngIf="translateService.currentLang === 'ja'">{{useremail}} {{'enterCode.title' | translate}}</p>
            <p style="margin-bottom: 10px" *ngIf="translateService.currentLang === 'zh'">请输入发送给 {{useremail}} 的 6 位数字代码</p>
            <code-input [isCodeHidden]="false" [codeLength]="6" (codeChanged)="onCodeChanged($event)"> </code-input>
            <p *ngIf="errorMsg" style="color: red">{{errorMsg}}</p>
        </ng-container>
        <ng-container *ngIf="validated">
            <div class="show-success">
                <cds-icon status="success" size="xxl" shape="success-standard"></cds-icon>
                <p>{{'successMsg.title' | translate}}</p>
            </div>
        </ng-container>
    </div>
    <div class="modal-footer">
        <ng-container *ngIf="showEmailInput && !validated">
            <button type="button" class="btn btn-primary" (click)="getOTP()" [disabled]="!validateEmail()">
                {{ 'sendVerificationCode.title' | translate}}
            </button>
        </ng-container>
        <ng-container *ngIf="!showEmailInput && !validated">
            <button type="button" class="btn btn-outline" [disabled]="resendTime > 0"
                (click)="getOTP()">{{ 'resend.title' | translate }} {{resendTime > 0 ? '('+resendTime+' sec)' : ''}}</button>
            <button type="button" class="btn btn-primary" [disabled]="!verificationCode || verificationCode.length != 6"
                [clrLoading]="validateBtnState" (click)="verfiyOTP()">{{ 'validation.title' | translate }}</button>
        </ng-container>
    </div>
</clr-modal>