import { Validators, AbstractControl, ValidationErrors } from "@angular/forms";

function validateEmails(users: any) {
    if(users.some((x: any) => x.mail == undefined)){
        return users.every((user: any) => Validators.email(<AbstractControl>{ value: user.trim() }) == null);
    }
    else{
    return users.every((user: any) => Validators.email(<AbstractControl>{ value: user.mail.trim() }) == null);
    }
}

export function emailsValidator(control: AbstractControl): ValidationErrors | null {
    let controlVal = control.value;
    if(controlVal){
        controlVal = Array.isArray(control.value) ? controlVal : controlVal.split(',');
        if (control.value === '' || !control.value || validateEmails(controlVal)) {
            return null
        }
        return { emails: true };
    }
}