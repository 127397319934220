<div [ngClass]="{'hide': (loading && !questions)}">
    <div class="clr-row nav-bar summary-wd" [ngClass]="{'no-banner': env.isProdMode &&  !checkShowBanner(), 'yes-banner': env.isProdMode && checkShowBanner()}">
        <div style="margin-left: 20px">
            <span *ngIf="vcpmSer.vcpmSavedData === undefined">{{ 'summary.title' | translate }}</span>
            <span *ngIf="vcpmSer.vcpmSavedData !== undefined">{{projectNameWithId }}</span>
        </div>
        <div class="download-btn">
            <button class="btn btn-primary" (click)="onSaveBtnClick(true)">{{'save.title' | translate}}</button>
            <button class="btn btn-primary" (click)="onImportAndExport()">{{ 'importOrExport.title' | translate }}</button>
            <button class="btn btn-primary" (click)="onDownload()">{{ 'download.title' | translate }}</button>
        </div>
    </div>
    <div class="report">
        <div class="areas-grid clr-col">
            <p class="title">{{ 'summary.title' | translate }}</p>
            <div class="clr-row color-declare clr-justify-content-end">
                <span class="clr-col-2" style="text-align: end">{{ 'gap.title' | translate }}</span>
                <div class="clr-col-2">
                    <div class="clr-row color-range">
                        <span class="clr-col" style="padding: unset">{{'min.title' | translate}}</span>
                        <span class="clr-col" style="text-align: center">{{'mid.title' | translate}}</span>
                        <span class="clr-col" style="text-align: end">{{'max.title' | translate}}</span>
                    </div>
                    <div class="clr-row"><span class="label gap-color"></span></div>
                </div>
            </div>
            <p class="help-text">{{ 'lable.title' | translate }}</p>
            <clr-datagrid>
                <clr-dg-column>{{ 'name.title' | translate }}</clr-dg-column>
                <clr-dg-column>{{ 'current.title' | translate }}</clr-dg-column>
                <clr-dg-column>{{ 'goal.title' | translate }}</clr-dg-column>
                <clr-dg-column>{{ 'gap1.title' | translate }}</clr-dg-column>

                <clr-dg-row *ngFor="let area of questions">
                    <clr-dg-cell>
                        {{area.description}}<a (click)="navigateToQuestionsPage(area.shortDesc)">
                            <cds-icon style="margin-bottom: 5px;margin-left: 10px" shape="pencil"></cds-icon>
                        </a>
                    </clr-dg-cell>
                    <clr-dg-cell>{{ area.currentAverage }}</clr-dg-cell>
                    <clr-dg-cell>{{ area.goalAverage }}</clr-dg-cell>
                    <clr-dg-cell>
                        <span class="label gap-lable" [ngStyle]="{'background-position': area.gapPercent + '% 0'}">{{
                            area.goalAverage -
                            area.currentAverage | number:'1.2-2'}}</span>
                    </clr-dg-cell>
                </clr-dg-row>
            </clr-datagrid>
        </div>
        <div class="report-grap">
            <div>
                <p class="title">{{'heatMap.title' | translate}}</p>
                <div class="clr-row grid-row" style="min-width: 1400px">
                    <div style="display: block" class="radar-chart clr-col-6">
                        <div class="chart-label clr-row">
                            <span class="label goal-label"></span>{{'customerGoals.title' | translate}}
                            <span class="label current-label"></span>{{'current.title' | translate}}
                        </div>
                        <canvas #radarChart baseChart [datasets]="radarChartData" [options]="radarChartOptions"
                            [labels]="radarChartLabels" [chartType]="radarChartType">
                        </canvas>
                    </div>
                    <div class="heatmap clr-col-6">
                        <div style="display: block">
                            <div class="clr-row" *ngIf="!spanishLangSel">
                                <span class="clr-col-3" style="text-align: end; max-width: 100px;">{{'gap.title' | translate}}</span>
                                <div class="clr-col-3" style="margin-top: -20px">
                                    <div class="clr-row color-range">
                                        <span class="clr-col" style="padding: unset">{{'min.title' | translate}}</span>
                                        <span class="clr-col" style="text-align: center">{{'mid.title' | translate}}</span>
                                        <span class="clr-col" style="text-align: end">{{'max.title' | translate}}</span>
                                    </div>
                                    <div class="clr-row"><span class="label gap-color"></span></div>
                                </div>
                            </div>
                            <div class="clr-row" *ngIf="spanishLangSel">
                                <span  style="max-width: 14%;">{{'gap.title' | translate}}</span>
                                <div class="clr-col-5" style="margin-top: -20px">
                                    <div class="clr-row color-range">
                                        <span class="clr-col" style="padding: unset">{{'min.title' | translate}}</span>
                                        <span class="clr-col" style="text-align: center">{{'mid.title' | translate}}</span>
                                        <span class="clr-col">{{'max.title' | translate}}</span>
                                    </div>
                                    <div class="clr-row"><span class="label gap-color"></span></div>
                                </div>
                            </div>
                            <div #heatmap id='heatmap' style="width: 100%; height: 100%;"></div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>
<div *ngIf="!loading && errorMsg">
    {{ 'errorMsg.title' | translate }}
</div>
<div *ngIf="loading" class="loading-icon" style="min-height: 80vh">
    <span class="spinner"></span>
</div>
<app-download-dialog [(openModal)]="showEmailVerificaion" (startDownload)="downloadReport()"></app-download-dialog>
<clr-modal class="import-export-dialog" [(clrModalOpen)]="showImportExport">
    <div class="modal-body">
        <div class="clr-row">
            <div class="clr-col">
                <h4> {{ 'import.title' | translate }} </h4>
                <p> {{ 'chooseFile.title' | translate}}</p>
                <input style="margin: 10px 0 20px 0px" type="file" id="file" (change)="handleFileInput($event)"
                    accept=".csv">
                <button style="float: right" [clrLoading]="importBtnState" class="btn btn-primary"
                    (click)="importCSV()">{{ 'chooseFile.title' | translate}}</button>
            </div>
            <div class="clr-col divide-line"></div>
            <div class="clr-col">
                <h4> {{ 'export.title' | translate }} </h4>
                <p style="margin-bottom: 3rem">{{ 'saveCSVFile.title' | translate }}</p>
                <button style="float: right" class="btn btn-primary" (click)="exportToCSV()">{{ 'exportCSV.title' | translate}}</button>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <clr-alert *ngIf="importError" [clrAlertType]="'danger'" [clrAlertClosable]="false">
            <clr-alert-item>
                <span class="alert-text">
                    {{importError}}
                </span>
            </clr-alert-item>
        </clr-alert>
    </div>
</clr-modal>
<app-new-project *ngIf="saveBtnState && (vcpmSer.vcpmSavedData == undefined)" [modalState]="saveBtnState" [formName]="formName" [btnText]="btnText"
(onModalBtnSelection)="onSaveBtnClick($event)"></app-new-project>
<alert></alert>