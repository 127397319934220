import { HttpClient } from '@angular/common/http';
import {Component, forwardRef, Inject, OnInit} from '@angular/core';
import { EnvironmentsService } from '@core/services/environments.service';
import { Observable } from 'rxjs';
import { SubSink } from 'subsink';

@Component({
  selector: 'release-notes',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.scss']
})
export class ReleaseNotesComponent implements OnInit {
  panelList: any[] = [];
  panelAll: any[] = [{ title: "", list: [] }];
  years: any[] = [];
  months: any[] = [];
  activeMon: number = 0;
  monthTitle: string = "";
  role: string
  subs: SubSink = new SubSink();
  loading: boolean;
  releaseNote: any = {};
  constructor(@Inject(forwardRef(() => EnvironmentsService)) private envService: EnvironmentsService,
              private http: HttpClient) { }

  ngOnInit(): void {
    this.getReleaseNotes();
  }
  getReleaseNotes() {
    this.loading = true;
    this.subs.sink = this.getReleaseNotesData().subscribe((releseNotes: any) => {
      if(releseNotes && releseNotes.length > 0 ) {
        // Sort release notes by lastUpdatedDate
        releseNotes.sort(function(a:any, b:any) {
          const x:any = (new Date(a.lastUpdatedDate));
          const y:any = (new Date(b.lastUpdatedDate));
          return y - x;
        });
        // Convert to release notes existing format
        releseNotes.forEach((note: any) => {
          if(!this.releaseNote.hasOwnProperty(note.year)) {
            this.releaseNote[note.year] = {};
          }
          if(!this.releaseNote[note.year].hasOwnProperty(note.version)) {
            this.releaseNote[note.year][note.version] = [];
          }
          this.releaseNote[note.year][note.version].push({ "releaseDate" : note?.releaseDate });
          this.releaseNote[note.year][note.version].push({ "content" : note?.content });
          this.releaseNote[note.year][note.version].push({ "lastUpdatedDate" : note?.lastUpdatedDate });
        });
        this.years = Object.keys(this.releaseNote);
        this.years = this.years.sort().reverse();
        this.swithYear(this.years[0]);
      }
      this.loading = false;
    }, (error: any) => {
      this.loading = false;
    });
  }
  swithYear(y: number) {
    this.months = Object.keys(this.releaseNote[y]);
    this.months.unshift("All");
    this.showAll(y);
  }
  showAll(y: number) {
    this.panelList = [];
    let months = this.months.slice(1);
    this.panelAll = months.map(r => {
      return {
        title: r,
        list: this.releaseNote[y][r]
      }
    })
  }
  showMonth(y: number, m: string, i: number) {
    this.panelAll = [];
    this.monthTitle = this.months[i];
    this.panelList = this.releaseNote[y][m];
  }
  swithMonth(y: number, m: string, i: number) {
    this.activeMon = i;
    switch (i) {
      case 0:
        this.showAll(y)
        break;
      default: this.showMonth(y, m, i);
    }
  }
  public getReleaseNotesData(): Observable<any> {
    return this.http.get(this.envService.config.wpConfigServiceUrl+"/configurationapi/v1/vmccma"+"/releasenotes");
  }

}
