import {Component, forwardRef, Inject, OnInit} from '@angular/core';
import { ActivatedRoute, Data, ParamMap, Router } from '@angular/router';
import { AreaQuestions } from '@core/models/questions';
import { AlertService } from '@core/services/alert.service';
import { AuthenticationService } from '@core/services/authentication.service';
import { EnvironmentsService } from '@core/services/environments.service';
import { VCPMService } from '@core/services/vcpm.service';
import { WhitneyService } from '@core/services/whitney.service';
import { TranslateService } from '@ngx-translate/core';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit {
  areaQuestions: AreaQuestions;
  loading: boolean = false;
  errorMsg: string;
  currentAverage: string;
  goalAverage: string;
  area: string;
  saveProjectModal: boolean;
  formName: any;
  btnText: any;
  projectNameWithId: string;

  constructor(private route: ActivatedRoute, private router: Router, private whitneyService: WhitneyService,
              @Inject(forwardRef(() => EnvironmentsService)) public env: EnvironmentsService,
              private translateService : TranslateService, public vcpmServ: VCPMService,
    private authenticationService:AuthenticationService,private alertService: AlertService) { }

  ngOnInit(): void {
    this.loading = true;
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        if(params.get('projectId') !== undefined && params.get('projectId') !== null){
          this.projectNameWithId = this.vcpmServ.analysisName +' (' +this.translateService.instant('analysisid.title') +' : '+params.get('projectId')+')';
        }
        return this.whitneyService.getQuestionsByArea(params.get('area'));

      }),
    ).subscribe(
      questions => {
        if (!questions) {
          if(window.location.href.includes('/questions/')){
            this.route.params.subscribe(params => {
              if(params.projectId !== undefined){
                this.router.navigate([`/results/${params.projectId}`])
                return;
              }else{
                this.router.navigateByUrl('');
                return;
              }
            });
          }
        }
        this.areaQuestions = questions;
        this.whitneyService.refreshAverageByArea(questions.id);
        if(this.vcpmServ.openSaveDialog){
          this.vcpmServ.openSaveDialog = false;
          this.onSaveBtnClick();
        }
        this.loading = false;
      },
      error => {
        this.loading = false;
        console.log(error);
        this.errorMsg = 'Failed to get the questions, please try again later!'
      }
    );
  }

  onCurrentChange(event, question) {
    question.currentVal['selected'] = Number(event.target.value);
    this.whitneyService.refreshAverageByArea(this.areaQuestions.id);
    this.updateInputChangedFlag();
  }

  onGoalChange(event, question) {
    question.goalVal['selected'] = Number(event.target.value);
    this.whitneyService.refreshAverageByArea(this.areaQuestions.id);
    this.updateInputChangedFlag();
  }

  getScoreDesc(value: number) {
    switch (value) {
      case 1:
        return this.translateService.instant('low.title')
      case 2:
        return this.translateService.instant('medLow.title')
      case 3:
        return this.translateService.instant('med.title')
      case 4:
        return this.translateService.instant('medHigh.title')
      case 5:
        return this.translateService.instant('high.title')
      default:
        return ""
    }
  }
  onQuestionClick(){
    if(window.location.href.includes('/questions/')){
      this.route.params.subscribe(params => {
        if(params.projectId !== undefined){
          this.router.navigate([`/results/${params.projectId}`])
        }else{
          this.router.navigate(['/results'])
        }
      });
    }
  }
  onSaveBtnClick(){
    this.saveProjectModal = true;
    if(this.authenticationService.isActiveUser()){
      if(this.vcpmServ.vcpmSavedData !== undefined){
        let languageChanged = this.vcpmServ.checkWhetherLanguageChangedOrNot();
        if(this.vcpmServ.inputChanged || languageChanged){
          let inputData = this.whitneyService.prepareInputData()
          let req = {
            "metadata" : this.vcpmServ.vcpmSavedData.metadata,
            "data":inputData
          }
          this.vcpmServ.updateAnalysis(this.vcpmServ.vcpmSavedData.projectId,req).subscribe((res : Data) => {
            this.vcpmServ.vcpmSavedData = res;
            // this.pushDataToSuperCollider(res);
            this.vcpmServ.inputChanged = false;
            this.vcpmServ.questionsPage = true;
            this.router.navigate([`/results/${res.projectId}`])
          },error => {
            this.alertService.error(this.translateService.instant('apiFailErrorMsg.title'),false)
          });
        }
      }else{
        this.formName = this.translateService.instant('saveAnalysis.title');
        this.btnText = this.translateService.instant('save.title');
      }
    }else{
      this.vcpmServ.redirectPage = `/questions/${this.areaQuestions.description}`;
      this.vcpmServ.navigateToLogin();
    }
  }
  closeSaveProject(flag){
    this.saveProjectModal = flag;
      if(this.vcpmServ.isProjectSaved){ //new projects save and navigating to results page
        this.router.navigate([`/results/${this.vcpmServ.vcpmSavedData.projectId}`])
      }
  }
  pushDataToSuperCollider(res){
    let req = {
      "superColliderData": res,
      "configurationGroup": "vmcam",
    }
    this.vcpmServ.pushDataToSuperCollider(req,res.projectId).subscribe(result => {
        this.vcpmServ.inputChanged = false;
        this.vcpmServ.questionsPage = true;
        this.router.navigate([`/results/${res.projectId}`])
    },error => {
        // this.alertService.error(this.translateService.instant('apiFailErrorMsg.title'),true);
    }
    );
  }
  updateInputChangedFlag(){
      this.vcpmServ.inputChanged = true;//whether the user is logged in or not we need to display a msg
  }

}
