import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class AlertService {
    private subject = new Subject<any>();

    success(message: string, value: boolean) {
        this.subject.next({ type: 'success', text: message, bool: value });
        if (value == true) {
            window.setTimeout(() => this.clear(), 3000);
        }
    }

    error(message: string, value: boolean) {
        this.subject.next({ type: 'danger', text: message, bool: value });
        if (value == true) {
            window.setTimeout(() => this.clear(), 3000);
        }
    }

    clear() {
        this.subject.next();
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}
