import {Component, forwardRef, Inject, OnDestroy, OnInit} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { EnvironmentsService } from '@core/services/environments.service';
import { PlatformLocation } from '@angular/common';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '@core/services/authentication.service';
import { WhitneyService } from '@core/services/whitney.service';
import { VCPMService } from '@core/services/vcpm.service';
import { User } from '@core/models/user';

@Component({
  selector: 'app-header',
  providers: [],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  deploymentEnv: {
    isTest: boolean;
    isStg: boolean;
    isProd: boolean;
    testUrl: string;
    stgUrl: string;
    prodUrl: string;
  };
  showLanguage: boolean;
  selectedLanguage: string;
  currentUser: User;
  reDirectTo = '';
  showBanner: boolean = false;
  // Define the end date to show the banner (Year, Month-1, Day)
  private endDate: Date = new Date(2024, 11, 15); // Example: December 15, 2024

  constructor(
      @Inject(forwardRef(() => EnvironmentsService)) private env: EnvironmentsService,
      private translateService: TranslateService, public authenticationService: AuthenticationService
    ,private router: Router,private whitneyService: WhitneyService, public vcpmservice:VCPMService
  ) {
    this.deploymentEnv = {
      isTest: this.env.isTestingMode,
      isStg: this.env.isStgMode,
      isProd: this.env.isProdMode,
      testUrl: this.env.config.phTestingEnvUrl,
      stgUrl: this.env.config.phStagingEnvUrl,
      prodUrl: this.env.config.phProductionEnvUrl,
    };
    if(localStorage.getItem('vmccma-lang') === null || localStorage.getItem('vmccma-lang') === 'en'){
      this.selectedLanguage = 'English'
    }else if(localStorage.getItem('vmccma-lang') === 'zh'){
      this.selectedLanguage = '中文'
    }else if(localStorage.getItem('vmccma-lang') === 'es'){
      this.selectedLanguage = 'Español'
    }else if(localStorage.getItem('vmccma-lang') === 'ja'){
      this.selectedLanguage = '日本語'
    }
    this.env.selectedLang = this.selectedLanguage
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit() {
  }

  changeLanguage(lang: string) {
    this.showLanguage = false;
    localStorage.setItem('vmccma-lang', lang);
    if(this.vcpmservice.inputChanged){
      this.vcpmservice.showAlert = true;
      this.reDirectTo = "lang"
    }else{
      location.reload();
    }
  }

  onLanguageClick(){
    this.showLanguage = true;
  }
  onHomeClick(){
    if(this.vcpmservice.inputChanged){
      this.vcpmservice.showAlert = true;
      this.reDirectTo = "/results"
    }
    else{
      this.navigateToResultsPage();
    }
  }

  onCancelClick(){
    this.showLanguage = false;
  }

  ngOnDestroy() {
  }
  logout() {
    let loggedout = this.authenticationService.logout();
      if(loggedout) {
        this.vcpmservice.redirectPage = '/results';
        this.whitneyService.isHomeClick = true;
        this.vcpmservice.vcpmSavedData = undefined;
        localStorage.setItem('vmccma-lang','en')
        this.translateService.use('en')
        this.selectedLanguage = 'English'
        this.vcpmservice.inputChanged = false;
        this.whitneyService.validatedUser = undefined;
        this.router.navigate(['/login']);
        }
  }
  onMyAnalysisClick(){
      if(this.authenticationService.currentUserValue !== null && this.authenticationService.currentUserValue !== undefined){
        if(this.vcpmservice.inputChanged){
          this.vcpmservice.showAlert = true;
          this.reDirectTo = "/saved-projects"
        }else{
          this.router.navigate(["/saved-projects"]) 
        }
      }else{
        this.vcpmservice.redirectPage = '/saved-projects';
        this.router.navigate(["/login"]);
      }
  }
  closeAlertDialog(flag){
    if(flag){
      this.navigateToResultsPage();
    }
    this.vcpmservice.showAlert = false;
  }
  checkSavedProjects(){
    return window.location.href.includes('/saved-projects')
  }
  navigateToResultsPage(){
    this.whitneyService.isHomeClick = true;
    this.router.navigateByUrl('.', {skipLocationChange: true}).then(() => {
      this.vcpmservice.vcpmSavedData = undefined;
      this.vcpmservice.inputChanged = false
      this.vcpmservice.openSaveDialog = false
      this.router.navigate(['/results'])
      });
  }
  onSignInClick(){
    this.vcpmservice.redirectPage = '/results';
    this.router.navigate(['/login'])
  }

  checkShowBanner() {
    const today = new Date();
    // Check if today is before or on the end date
    this.showBanner = today <= this.endDate;
    return this.showBanner;
  }
}