import { Component, OnInit } from '@angular/core';
import { VCPMService } from '@core/services/vcpm.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  copyRightText: string;

  constructor(public vcpmSer: VCPMService) {
    this.copyRightText = `${this.getCopyrightText()}. All Rights Reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries.`;
  }

  ngOnInit() {
  }
  getCopyrightText() {
    return `Copyright © 2005-${new Date().getFullYear()} Broadcom`
  }
}
