<clr-modal [clrModalOpen]="modalState" [clrModalClosable]="false" [clrModalStaticBackdrop]="true"
      [clrModalSize]="'lg'">
      <h3 class="modal-title">
        {{analysisTitle}}
        <span class="close-icon" style="float:right">
          <cds-icon shape="times" (click)="cancelOrClose()"
            id="generalAssumptionsClose"></cds-icon>
        </span>
      </h3>
      <div class="modal-body">
        <form [formGroup]="newProjectForm" clrForm>
          <div class="clr-row">
            <label class="clr-col-3 control-label">{{'analysisName.title' | translate}}
              <span style="color: crimson;font-size: 17px;margin-left: 2PX;"><b>*</b></span>
            </label>
            <input clrInput  type="text" class="control-content" style=" width: 108% !important;"formControlName="analysisName" autocomplete="off">
          </div>
          <div class="clr-row">
            <label class="clr-col-4 control-label">{{'partner.title' | translate}}
              <span style="color: crimson;font-size: 17px;margin-left: 2PX;"><b>*</b></span>
            </label>
              <input clrInput type="text" style="width:125%;margin-left:-55px"  class="control-content" name="mngSerUplift"  formControlName="companyName" autocomplete="off"
               >
          </div>
          <div class="clr-row">
            <label class="clr-col-4 control-label" for="mForm_14">{{'testAnalysis.title' | translate}}
            </label>
            <div class="clr-col-6">
                <clr-toggle-wrapper>
                    <input type="checkbox" clrToggle formControlName="testAnalysis" (change)="checkValue()"/>
                </clr-toggle-wrapper>
            </div>
          </div>
          <ng-container>
            <clr-combobox-container>
                <label class="clr-control-label clr-col-12 clr-col-md-3" style="color:white">
                  {{'partnerid.title' | translate}}
                  <span *ngIf="newProjectForm.get('testAnalysis').value === false" style="color: crimson;font-size: 17px;margin-left: 2PX;"><b>*</b></span>
                </label>
                <clr-combobox class="clr-col-md-9" (clrSelectionChange)="selChange($event)" 
                formControlName="sfdcId" name="myState" [clrLoading]="loadingUsers" (clrInputChange)="onTypingCustomerID($event)" >
                  <clr-options class="user-options">
                    <clr-option *clrOptionItems="let user of results;field:'fullName'" [clrValue]="user">
                      <ng-container *ngIf="user.fullName">
                        {{user.id}} ({{user.name}})
                      </ng-container>
                    </clr-option>
                  </clr-options>
                </clr-combobox>
            </clr-combobox-container>
          </ng-container>
          <div class="clr-row">
            <label class="clr-col-3 control-label">{{'opportunityId.title' | translate}}
              <span *ngIf="newProjectForm.get('testAnalysis').value === false" style="color: crimson;font-size: 17px;margin-left: 2PX;"><b>*</b></span></label>
                  <div class="clr-col-sm-12 clr-col-md-6 clr-col-lg-6" style="margin-top:-12px">
                    <div class="select">
                      <select class="control-content" clrSelect style="width: 318px;
                      margin-left: 6px;" formControlName="opportunity" (change)="onChangeopportunityID($event)">            
                      <option style="max-width: 318px;white-space: pre-wrap"[ngValue]="opp" *ngFor="let opp of opportunityIDs" let i=index>
                          {{ opp.id }}
                          - {{opp.name}}
                      </option>
                  </select>
                    </div>
                  </div>
                  <span *ngIf="showErrorMsg" class="error" style="color:red;margin-left:288px">
                    {{'noOpportunity.title' | translate}}
                </span>
          </div>
          <div class="clr-row">
            <label class="clr-col-3 control-label">{{'tags.title' | translate}}
              <clr-tooltip>
                <cds-icon clrTooltipTrigger shape="info-circle" size="24"></cds-icon>
                <clr-tooltip-content clrSize="md" *clrIfOpen>
                  <span>{{'tagTooltip.title' | translate}}</span>
                </clr-tooltip-content>
              </clr-tooltip>
            </label>
              <input clrInput type="text" class="control-content" style=" width: 108% !important;" name="mngSerUplift"  formControlName="tags" autocomplete="off"
               >
          </div>
          <div class="clr-row">
            <label class="clr-col-3 control-label">{{'desc.title' | translate}}
            </label>
              <textarea clrTextarea [maxlength]="1000" formControlName="description" rows="6" class="clr-textarea"
               placeholder="{{'descPlaceholder.title' | translate}}"
               ></textarea>
          </div>
        </form>        
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="cancelOrClose()" id="resetAllTabs">{{'cancel.title'| translate}}</button>
  <button type="button" class="btn btn-primary"  id="saveAll" (click)="save()"
        [disabled]="disableSaveBtn()">{{createOrUpdatebtnText}} 
      </button>
  <clr-tooltip *ngIf="disableSaveBtn()">
    <cds-icon clrTooltipTrigger shape="exclamation-triangle" class="is-solid" size="41">
    </cds-icon>
    <clr-tooltip-content clrPosition="top-left" clrSize="lg" *clrIfOpen>
        <span>{{'mandatoryFields.title' | translate}}</span>
    </clr-tooltip-content>
</clr-tooltip>
      </div>
</clr-modal>