import { HttpClient } from "@angular/common/http";
import {forwardRef, Inject, Injectable} from "@angular/core";
import { EMPTY, Observable } from "rxjs";
import { EnvironmentsService } from "./environments.service";
import { AreaQuestions } from "@core/models/questions";
import { Data, VCPMResponse } from "@core/models/vcpmresponse";
import { Metadata } from "./metadata";
import { AuthenticationService } from "./authentication.service";
import { Router } from "@angular/router";

@Injectable({
    providedIn: 'root',
  })
  export class VCPMService {
    vcpmUrl: string;
    inputChanged: boolean = false;
    isProjectSaved: boolean;
    vcpmSavedData: any;
    inputs: AreaQuestions[];
    analysisName = "";
    showAlert: boolean;
    redirectPage = "";
    questionsPage = false;
    openSaveDialog = false;
    saveBtnState: boolean;
    
    constructor(private http: HttpClient,
                @Inject(forwardRef(() => EnvironmentsService))private env: EnvironmentsService,
                private router: Router) {
        this.vcpmUrl = `${this.env.config.vcpmUrl}`;
    }

    saveAnalysis(obj): Observable<VCPMResponse> {
      return this.http.post<VCPMResponse>(`${this.vcpmUrl}/projects`,obj)
    }

    getAnalysisDetails(projectId):Observable<VCPMResponse> {
      return this.http.get<VCPMResponse>(`${this.vcpmUrl}/projects/${projectId}`)
    }

    //Ignroing the data push to Super Collider
    pushDataToSuperCollider(req,projectId){
      return this.http.put<any>(`${this.vcpmUrl}/projects/supercollider?projectId=${projectId}`,req);
    }

    getProjectsMetadata():Observable<Metadata[]>{
      return this.http.post<Metadata[]>(`${this.vcpmUrl}/projects/metadata`,{});
    }

    updateAnalysis(projectId,obj): Observable<VCPMResponse> {
      return this.http.put<VCPMResponse>(`${this.vcpmUrl}/projects?projectId=${projectId}`,obj)
    }

    deleteAnalysis(projectId): Observable<VCPMResponse> {
      return this.http.put<VCPMResponse>(`${this.vcpmUrl}/projects/${projectId}/metadata?action=deactivate`,{});
    }

    shareAnalysis(projectId: number, payload): Observable<any>{
      return this.http.post<any>(`${this.vcpmUrl}/projects/${projectId}/collaboration`, payload);
    }

    updateMetadata(projectId,obj): Observable<VCPMResponse> {
      return this.http.put<VCPMResponse>(`${this.vcpmUrl}/projects/${projectId}/metadata?isAdminUserRequest=false`,obj);
    }
    navigateToLogin(){
      this.openSaveDialog = true;
      this.router.navigate(['/login'])
    }
    checkWhetherLanguageChangedOrNot(){
      let languageChanged = false;
      if(localStorage.getItem("vmccma-lang") !== null && (localStorage.getItem("vmccma-lang") !== this.vcpmSavedData.metadata.locale)){
        languageChanged = true;
        this.vcpmSavedData.metadata.locale = localStorage.getItem("vmccma-lang")
        return languageChanged;
      }else{
        return languageChanged;
      }
    }
  }