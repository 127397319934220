import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ClrCombobox } from '@clr/angular';
import { AlertService } from '@core/services/alert.service';
import { AuthenticationService } from '@core/services/authentication.service';
import { emailsValidator } from '@core/services/email.validator';
import { VCPMService } from '@core/services/vcpm.service';
import { WhitneyService } from '@core/services/whitney.service';
import { TranslateService } from '@ngx-translate/core';
let helper = new JwtHelperService();
@Component({
  selector: 'share-project',
  templateUrl: './share-project.component.html',
  styleUrls: ['./share-project.component.scss']
})
export class ShareProjectComponent implements OnInit {
  @Input() shareProject: boolean;
  @Output() closeShareProject = new EventEmitter<{shared: boolean}>();
  @ViewChild(ClrCombobox, { static: true }) combobox: any;
  shareForm: any;
  userKeyword: string;
  userList: any[];
  selectedItems = [];
  loadingUsers: boolean;
  shareTypeList = [this.translateService.instant('shareCopy.title')]
  shareType = this.shareTypeList[0];
  settings = {
    text: "Select Users",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    classes: "myclass custom-class",
    primaryKey: "mail",
    labelKey: "mail",
    noDataLabel: "No Results",
    enableSearchFilter: true,
    maxHeight:100,
    badgeShowLimit:2,
    singleSelection: false
};
  error = ' ';
  espToken: any;
  constructor(private fb: UntypedFormBuilder,private vcpmService: VCPMService,private alertService: AlertService,
    private whitneySer:WhitneyService,private translateService: TranslateService,private authenticationService: AuthenticationService) { 
    this.selectedItems = []
  }

  ngOnInit() {
    this.getEspToken();
    this.shareForm = this.fb.group(
      {
        toUser: new UntypedFormControl('',[Validators.required, emailsValidator]),
        shareType: new UntypedFormControl(this.shareType),
      });
  }
  getEspToken(){
    let user = JSON.parse(localStorage.getItem('currentUser'));
    let req = {
      "refresh_token" : user.refresh_token
    }
    this.whitneySer.getAccessTokenFromRefreshToken(req,'esp').subscribe((resp) => {
      this.espToken = resp.access_token;
      let user = JSON.parse(localStorage.getItem('currentUser'));
      user.refresh_token = resp.refresh_token
      localStorage.setItem('currentUser', JSON.stringify(user));
      this.authenticationService.currentUserSubject.next(user);
    })
  }
  
  removeEmptyProperties(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj
  }
  setToList(){
    let toList = "";
    for(let i in this.selectedItems){
      if(this.selectedItems.length === 1){
        toList = this.selectedItems[i].mail;
      }else if(Number(i) < this.selectedItems.length){
        toList = toList + this.selectedItems[i].mail + ','
      }
    }
    if(this.selectedItems.length > 0){
      let userList = this.selectedItems;
      let user = JSON.parse(localStorage.getItem('currentUser'));
      if (userList && userList.some((x: any) => x.mail == user.mail)) {
        this.error = this.translateService.instant('shareErrorMsg.title');
      } else {
        this.error = ' ';
      }
    }else{
        this.error = ' ';
    }
  }
  modalHandler(){
    this.closeShareProject.emit({shared: false});
  }
  shareProjectClick(){
    let shareData;
    let payload = [];
    shareData = this.shareForm.value;
    shareData["toUser"] = shareData["toUser"].split(',');
    shareData["toUser"].forEach((element: any) => {
      payload.push({
        toUser: element.trim(),
        shareType: "shareCopy"
      });
    }); 
    let user = JSON.parse(localStorage.getItem('currentUser'));
    if (payload.some(x => x.toUser == user.email)) {
      this.error = this.translateService.instant('shareErrorMsg.title');
      return;
    }
    this.vcpmService.shareAnalysis(this.vcpmService.vcpmSavedData.projectId, payload)
        .subscribe((res:any) => {
          if(res.status === 200){
            // this.pushDataToSuperCollider(res);
            this.closeShareProject.emit({shared: true}); 
          }else{          
            this.alertService.error(this.translateService.instant('failedshareAnalysis.title'), true);
            this.closeShareProject.emit({shared: true});
          }
          this.selectedItems = [];
        },err => {
            this.alertService.error(this.translateService.instant('failedshareAnalysis.title'), true);
          });
  }
  selChange(e: any){
    this.selectedItems = [];
    if(e.model == null){
      this.error = ''
    }else{
      this.selectedItems = e.model;
    }
    this.setToList();
  }

  disableShareBtn(){
    return this.error !== ' ' || this.shareForm.status === 'INVALID';
      //return this.selectedItems.length === 0 || this.error !== ' ';
  }
  pushDataToSuperCollider(res){
    let req = {
      "superColliderData": res,
      "configurationGroup": "vmcam",
    }
    this.vcpmService.pushDataToSuperCollider(req,res.projectId).subscribe(res => {
      this.closeShareProject.emit({shared: true}); 
    },error => {
      this.alertService.error(this.translateService.instant('apiFailErrorMsg.title'),false)
    });
  }
  onTypingUser(){
    this.error = ' '
  }
}
