<div *ngIf="loading" class="spinner-container">
    <span class="spinner" *ngIf="loading"></span>
</div>

<div class="" *ngIf="releaseNote && (releaseNote | json) != '{}'">
    <clr-tabs class="release-notes" clrLayout="vertical">
        <clr-tab *ngFor="let year of years">
            <button (click)="swithYear(year)" clrTabLink>{{year}}</button>
            <clr-tab-content *clrIfActive>
                <div class="yearResult">
                    <clr-vertical-nav>
                        <a *ngFor="let month of months,let i= index" [ngClass]="{'active':activeMon==i}" clrVerticalNavLink
                            (click)="swithMonth(year,month,i)" routerLinkActive="active">
                            {{month}}
                        </a>
                    </clr-vertical-nav>
                    <div class="single-content" [hidden]="!panelList.length">
                        <div class="card">
                            <div class="card-header"> {{monthTitle}} </div>
                            <div class="card-block">
                                <div class="month-content">
                                    <div *ngFor="let panel of panelList">
                                        <p class="month-content-title" *ngIf="panel.title">{{panel.title}}:</p>
                                        <p class="month-content-title mb-1" *ngIf="panel.releaseDate">
                                            <clr-icon shape="alarm-clock"></clr-icon> <span style="font-size: 0.5rem;">Updated On: {{panel.releaseDate | date:'longDate'}}</span>
                                        </p>
                                        <section *ngIf="panel.content" style="margin: 10px;" [innerHTML]="panel.content"></section>
                                        <section *ngIf="panel.subTitle" [innerHTML]="panel.subTitle"></section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <clr-accordion [hidden]="!panelAll.length" [clrAccordionMultiPanel]="true">
                        <clr-accordion-panel  [clrAccordionPanelOpen]="true" *ngFor="let panel of panelAll,let i = index">
                            <clr-accordion-title>{{panel.title}}</clr-accordion-title>
                            <clr-accordion-content *ngIf="panel.list.length">
                                <div class="month-content">
                                    <div *ngFor="let monthMod of panel.list">
                                        <p class="month-content-title" *ngIf="monthMod.title">{{monthMod.title}}:</p>
                                        <p class="month-content-title release-date" *ngIf="monthMod.releaseDate">
                                            <clr-icon shape="alarm-clock"></clr-icon> <span style="font-size: 0.5rem;">Updated On:
                                            {{monthMod.releaseDate | date:'longDate'}}</span>
                                            {{monthMod.lastUpdatedDate}}
                                        </p>
                                        <section *ngIf="monthMod.content" [innerHTML]="monthMod.content">
                                        </section>
                                        <section *ngIf="monthMod.subTitle" [innerHTML]="monthMod.subTitle"></section>
                                    </div>
                                </div>
                            </clr-accordion-content>
                        </clr-accordion-panel>
                    </clr-accordion>
                </div>
            </clr-tab-content>
        </clr-tab>
    </clr-tabs>
</div>
