import { DatePipe, formatDate } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { jsPDF } from "jspdf";
import 'jspdf-autotable'
import { forkJoin } from "rxjs";
@Injectable({
    providedIn: 'root',
})
export class PDFService {

    private pdfDoc: any;
    private pdfData: any;
    private datePipe = new DatePipe('en-US');
    private headingDefaultX = 15;
    private headingDefaultY = 15;
    private autoTableDefaultY = 20;
    private defaultFontSize = 14;
    private defaultHeadingSize = 16;
    private defaultBigHeadingSize = 21;
    private imgDefaultX = 35;
    private imgDefaultY = 30;
    private imgDefaultWidth = 210;
    private imgDefaultHeight = 110;
    private urlRegex = /https?:\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g;
    private isSetupFontDone = false;
    private cacheDoc: any;

    constructor(private httpClient: HttpClient,private translateService: TranslateService) {
        this.pdfDoc = new jsPDF({ orientation: 'landscape' });
        this.setupFont();
    }

     setupFont() {
        forkJoin(
            [
                this.httpClient.get('assets/fonts/chinese-regular.ttf', { responseType: 'text' }),
                this.httpClient.get('assets/fonts/chinese-bold.ttf', { responseType: 'text' }),
                this.httpClient.get('assets/fonts/Japanese-bold.ttf', { responseType: 'text' }),
                this.httpClient.get('assets/fonts/jap-regular.ttf', { responseType: 'text' })
            ]
        ).subscribe(
            response => {
                this.pdfDoc.addFileToVFS("Chinese.ttf", response[0]);
                this.pdfDoc.addFont("Chinese.ttf", "Chinese", "normal");
                this.pdfDoc.addFileToVFS("ChineseBold.ttf", response[1]);
                this.pdfDoc.addFont("ChineseBold.ttf", "ChineseBold", "bold");
                this.pdfDoc.addFileToVFS("Japanese.ttf", response[2]);
                this.pdfDoc.addFont("Japanese.ttf", "Japanese", "bold");
                this.pdfDoc.addFileToVFS("JapaneseNormal.ttf", response[3]);
                this.pdfDoc.addFont("JapaneseNormal.ttf", "JapaneseNormal", "normal");
                this.isSetupFontDone = true;
                this.cacheDoc = this.pdfDoc;
            },
            error => {
                console.log(error);
            }
        )
    }

    /*private checkSetup() {
        if ((this.translateService.currentLang === 'zh' || this.translateService.currentLang === 'ja') && !this.isSetupFontDone) {
            return false;
        }
        else
            return true;
    }*/

    /**
     * Initializwe PDF and return success / failure status
     */
    private init(): boolean {
        //this.pdfDoc = new jsPDF({ orientation: 'landscape' });
        if(!this.isSetupFontDone){
            return false;
        }
        this.pdfDoc = this.cacheDoc;
        var pageCount = this.pdfDoc.internal.getNumberOfPages();
        if(pageCount > 1){
            this.deletePage(pageCount);
        }
        var img = new Image();
        img.src = 'assets/images/pdf-brand.png';
        this.pdfDoc.addImage(img, 'png', 0, 20, 297, 170);
        if(this.translateService.currentLang === 'zh'){
            this.pdfDoc.setFont("Chinese", 'normal');
            this.pdfDoc.setFontSize(30);
        }else if(this.translateService.currentLang === 'ja'){
            this.pdfDoc.setFont("JapaneseNormal", 'normal');
            this.pdfDoc.setFontSize(25);
        }else if(this.translateService.currentLang === 'es'){
            this.pdfDoc.setFont("helvetica", 'normal');
            this.pdfDoc.setFontSize(25);
        }
        else{
            this.pdfDoc.setFont("helvetica", 'normal');
            this.pdfDoc.setFontSize(30);
        }
        this.pdfDoc.setTextColor(26, 66, 138);
        if(this.translateService.currentLang === 'ja'){
            this.pdfDoc.text(this.translateService.instant('home.title'), 30,80);
            this.pdfDoc.setFontSize(this.defaultHeadingSize);
            this.pdfDoc.setTextColor(63, 63, 63);
            this.pdfDoc.text(this.datePipe.transform(new Date(), 'MMMM d, yyyy'), 30, 90);
        }else if(this.translateService.currentLang === 'es'){
            this.pdfDoc.text(this.translateService.instant('home.title'), 20,80);
            this.pdfDoc.setFontSize(this.defaultHeadingSize);
            this.pdfDoc.setTextColor(63, 63, 63);
            this.pdfDoc.text(this.datePipe.transform(new Date(), 'MMMM d, yyyy'), 20, 90);
        }
        else{
            this.pdfDoc.text(this.translateService.instant('home.title'), 60,80);
            this.pdfDoc.setFontSize(this.defaultHeadingSize);
            this.pdfDoc.setTextColor(63, 63, 63);
            this.pdfDoc.text(this.datePipe.transform(new Date(), 'MMMM d, yyyy'), 220, 90);
        }
        this.addFooter();
        return true;
    }

    /**
   * Download PDF
   */
    downloadPDF(pdfData: Array<{ type: string, heading?: any, headers?: Array<any>, data: any }>) {
        if(!this.init()){
            setTimeout(() => {
                console.log("retry for init");
                this.downloadPDF(pdfData);
            }, 1000);
            return;
        }
        this.pdfData = pdfData;
        if (this.pdfData.length > 0) {
            pdfData.forEach((pageData) => {
                switch (pageData.type) {
                    case 'table':
                        this.addTable(pageData);
                        break;
                    case 'image':
                        this.addImage(pageData);
                        break;
                    default:
                }
            });
        }
        if(this.translateService.currentLang === 'zh'){
            this.pdfDoc.save("Private Cloud Maturity Model Assessment-Chinese-" + formatDate(new Date(), 'yyyyMMddhhmmsss', 'en_US') + ".pdf");
        }else if(this.translateService.currentLang === 'ja'){
            this.pdfDoc.save("Private Cloud Maturity Model Assessment-Japanese-" + formatDate(new Date(), 'yyyyMMddhhmmsss', 'en_US') + ".pdf");

        }else if(this.translateService.currentLang === 'es'){
            this.pdfDoc.save("Private Cloud Maturity Model Assessment-Spanish-" + formatDate(new Date(), 'yyyyMMddhhmmsss', 'en_US') + ".pdf");
        }else{
            this.pdfDoc.save("Private Cloud Maturity Model Assessment-" + formatDate(new Date(), 'yyyyMMddhhmmsss', 'en_US') + ".pdf");
        }
        this.pdfDoc = new jsPDF({ orientation: 'landscape' });
    }

    /**
     * Add Table in PDF
     * @param pageData
     * Sample pageData.headers = ['header', 'header', 'header']
     * Sample pageData.data = ['data','data,'data], ['data','data,'data]
     */
    private addTable(pageData: any) {
        const head = pageData.headers.map((item: any) => { return [item] });
        if (pageData.data.length > 0) {
            this.pdfDoc.addPage();
            this.pdfDoc.setFontSize(this.defaultFontSize);
            // this.pdfDoc.setFont("helvetica", 'bold');
            let lang;
            let fontStyle;
            let bodyLang;
            let bodyfontStyle;
            let colstyles;
            if(this.translateService.currentLang === 'zh'){
                this.pdfDoc.setFont("ChineseBold", 'bold');
                lang = "ChineseBold";
                fontStyle = "bold";
                bodyLang = "Chinese";
                bodyfontStyle = "normal"
            }else if(this.translateService.currentLang === 'ja'){
                this.pdfDoc.setFont("Japanese", 'bold');
                lang = "Japanese";
                fontStyle = "bold"
                bodyLang = "JapaneseNormal";
                bodyfontStyle = "normal"
            }else{
                this.pdfDoc.setFont("helvetica", 'bold');
                lang = "helvetica";
                fontStyle = "bold";
                bodyLang = "helvetica";
                bodyfontStyle = "normal"
            }
            if(this.pdfDoc.internal.getCurrentPageInfo().pageNumber === 2){
                colstyles = {
                    0: {cellWidth: 200},

                }
            }else{
                colstyles = {
                    0: {cellWidth: 18},
                    1: {cellWidth: 200},
                    2: {cellWidth: 16},
                    3: {cellWidth: 15},
                }
            }
            this.pdfDoc.setTextColor(63, 63, 63);
            this.pdfDoc.text(pageData.heading, this.headingDefaultX, this.headingDefaultY);
            let autotablePos = this.autoTableDefaultY;
            this.pdfDoc.autoTable({
                head: [head],
                body: pageData.data,
                styles: { showFoot: 'everyPage' },
                columnStyles: colstyles,
                startY: autotablePos,
                headStyles: {
                    font: lang,
                    fontStyle: fontStyle,
                },
                bodyStyles: {
                    font: bodyLang,
                    fontStyle: bodyfontStyle
                },
                didDrawCell: (data) => {
                    const content: string = data.cell.raw.content;
                    if (content && data.column.index == 4) {
                        let match;
                        const links = []
                        while ((match = this.urlRegex.exec(content)) !== null) {
                            links.push(match[0]);
                        }
                        if (links.length > 0) {
                            let y = data.cell.y + data.cell.contentHeight - 8 - (4 * links.length);
                            const color = data.doc.getTextColor();
                            data.doc.setFont("helvetica", 'bold');
                            data.doc.text("Reference links:", data.cursor.x, y)
                            y += 5;
                            data.doc.setFont("helvetica", 'normal');
                            data.doc.setTextColor("#0074cc");
                            links.forEach(link => {
                                data.doc.textWithLink(link, data.cursor.x, y, { url: link, maxWidth: data.cell.width + 0.3});
                                if(this.translateService.currentLang === 'ja'){
                                    y += 7 * (Math.ceil(link.length / 70));
                                }else if(this.translateService.currentLang === 'zh'){
                                    y += 6 * (Math.ceil(link.length / 70));
                                }else{
                                    y += 5 * (Math.ceil(link.length / 70));
                                }
                            })
                            data.doc.setTextColor(color);
                        }
                    }
                }
            });
            this.addFooter();
        }
    }

    /**
     * Add Table in PDF
     * @param pageData
     * Sample pageData.data = data.URI
     */
    private addImage(pageData: any) {
        this.pdfDoc.addPage();
        this.pdfDoc.setFillColor(27, 42, 50);
        this.pdfDoc.rect(this.imgDefaultX, this.imgDefaultY, this.imgDefaultWidth + 20, this.imgDefaultHeight + 20, "F");
        //this.pdfDoc.setFont("helvetica", 'bold');
        if(this.translateService.currentLang === 'zh'){
            this.pdfDoc.setFont("ChineseBold", 'bold');
        }else if(this.translateService.currentLang === 'ja'){
            this.pdfDoc.setFont("Japanese", 'bold');
        }else{
            this.pdfDoc.setFont("helvetica", 'bold');
        }
        this.pdfDoc.setFontSize(this.defaultFontSize);
        this.pdfDoc.setTextColor(63, 63, 63);
        this.pdfDoc.text(pageData.heading, this.headingDefaultX, this.headingDefaultY);
        var img = new Image();
        img.src = `assets/images/${pageData.graph}.png`;
        this.pdfDoc.addImage(img, 'png', this.imgDefaultX, this.imgDefaultY, pageData.graph == 'radar' ? 70 : 80, 10);
        this.pdfDoc.addImage(pageData.data, 'png', this.imgDefaultX + 10, this.imgDefaultY + 10, this.imgDefaultWidth, this.imgDefaultHeight);
        this.addFooter();
    }

    /**
   * Add footer to pages in PDF
   */
    private addFooter() {
        const vmwareLogoImg = new Image();
        vmwareLogoImg.src = 'assets/images/VMware-by-Broadcom-Logo_black.png';
        this.pdfDoc.addImage(vmwareLogoImg, 'PNG', 5, 198.5, 20.5, 6.8);
        this.pdfDoc.setFontSize(10);
        this.pdfDoc.setTextColor.apply(this, [0, 0, 0]);
        this.pdfDoc.setFont("helvetica", 'normal');
        let copyRightText = `Copyright © 2005-${new Date().getFullYear()} Broadcom. All Rights Reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries.`;
        this.pdfDoc.text('Confidential | ' + copyRightText, 37, this.pdfDoc.internal.pageSize.height - 5);
        let str = "" + (this.pdfDoc.internal.pages.length - 1);
        this.pdfDoc.text(str, 280, this.pdfDoc.internal.pageSize.height - 5);
        const vmwarePDFOrPPTBorderImg = new Image();
        vmwarePDFOrPPTBorderImg.src = 'assets/images/vmwarePDFOrPPTBorder.png';
        this.pdfDoc.addImage(vmwarePDFOrPPTBorderImg, 'PNG', 0, 208, 300, 3);
    }
    deletePage(pageno){
        if(pageno > 1){
            this.pdfDoc.deletePage(pageno);
            pageno = pageno - 1;
            this.deletePage(pageno)
        }
    }
}
