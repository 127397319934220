<clr-modal [(clrModalOpen)]="shareProject" [clrModalClosable]="false" [clrModalSize]="'md'" *ngIf="selectedItems!==undefined"> 
    <h3 class="modal-title">{{'shareAnalysistooltip.title' | translate}}
        <span class="close-icon">
            <clr-icon shape="times" (click)="modalHandler()"></clr-icon>
        </span>
    </h3>
    <div class="modal-body">
        <form clrForm [formGroup]="shareForm">
            <ng-container>
                <clr-input-container style="margin-bottom: 2rem;">
                  <label class="clr-control-label clr-col-12 clr-col-md-3">{{'users.title' | translate}}</label>
                  <input placeholder="{{'emailvalid.title' | translate}}" style="width:18rem" clrInput 
                  class="clr-col-md-9" formControlName="toUser" (blur)="onTypingUser()"/>
                  <clr-control-error>
                      <div *ngIf="shareForm['controls'].toUser.errors?.emails" style="padding-top: 0.5rem;">
                      <span class="error-class">{{'incorrectemail.title' | translate}}</span> 
                  </div>
                  </clr-control-error>
              </clr-input-container>
                <clr-select-container>
                    <label class="clr-control-label clr-col-12 clr-col-md-3">{{'shareType.title' | translate}}</label>
                    <select clrSelect formControlName="shareType" class="clr-col-md-9" style="width: 18rem; height: 1.4rem;" id="shareType">
                      <ng-container *ngFor="let shareType of shareTypeList; let i = index">
                          <option value="{{shareType}}">{{shareType}}</option>
                      </ng-container>
                    </select>
                  </clr-select-container>
            </ng-container>
        </form>
          <p *ngIf="error !== ''" class="error">{{error}}</p>
        </div>
    <div class="modal-footer">
        <button class="btn btn-outline" (click)="modalHandler()">{{'cancel.title' | translate}}</button>
        <button class="btn btn-primary" (click)="shareProjectClick()" [disabled]="disableShareBtn()">{{'share.title' | translate}}</button>
    </div>
</clr-modal>
<alert></alert>