<clr-alerts>
  <clr-alert *ngIf="!deploymentEnv.isProd" [clrAlertType]="'warning'" [clrAlertAppLevel]="true" class="env-alert" [clrAlertClosable]="false">
    <clr-alert-item>
      <span class="alert-text">
        You are currently viewing the
        {{ deploymentEnv.isTest ? 'TESTING' : deploymentEnv.isStg ? 'STAGING' : 'DEVELOPMENT' }}
        environment.
      </span>
    </clr-alert-item>
  </clr-alert>
</clr-alerts>
<header class="header-7">
  <div class="branding">
    <a href="https://www.vmware.com" target="_blank" class="nav-link" style="margin-left:4%">
      <img alt="VMware Logo" class="image" aria-label="VMware Logo" src="assets/images/VMware-by-Broadcom-Logo_white.png" />
    </a>
    <div class="logo-brand">
    <span class="title cursor" (click)="onHomeClick()"
    >{{ 'home.title' | translate }}</span>
  </div>
  </div>
  <ng-container>
    <div class="header-nav" [clr-nav-level]="1" style="margin-left:3%">
      <a class="nav-link cursor" title="{{'myAnalysis.title' | translate}}" 
      (click)="onMyAnalysisClick()" routerLinkActive="active" [ngClass]="checkSavedProjects() ? 'active' : '' ">
        <span class="title">
          {{'myAnalysis.title' | translate}}
        </span>
      </a>
    </div>
  </ng-container>
  <div class="header-actions">
    <div class="header-nav">
      <a class="nav-link cursor" id="feedback-form" title="Feedback">
          Feedback
      </a>
    </div>
    <clr-dropdown id="help">
      <button class="nav-text hidden-sm-up" clrDropdownTrigger>
          Help
          <cds-icon shape="angle" direction="down"></cds-icon>
      </button>
      <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
                  <a routerLink="/release-notes" routerLinkActive="active" class="cursor"
                  id="releaseNotes" title="Release Notes" clrDropdownItem>
                  Release Notes
                  </a>                  
      </clr-dropdown-menu>
  </clr-dropdown>
  <!-- <div class="header-nav">
    <a class="nav-link cursor-pointer" href="javascript:void(0)" (click)="onLanguageClick()" title="Feedback">
      <cds-icon shape="world" style="width:17px;margin-top: 3px;margin-left: -33%;"></cds-icon>
        {{selectedLanguage}}
  </a>
    <a href="javascript:void(0)" (click)="onLanguageClick()" style="text-decoration: none; color: white;margin-top:18px">
      <cds-icon shape="world" style="width:17px;margin-top: -3px;"></cds-icon>
        {{selectedLanguage}}
    </a>
  </div> -->
  <clr-dropdown [clrCloseMenuOnItemClick]="false">
    <button clrDropdownTrigger aria-label="User">
        <cds-icon shape="user" size="15"></cds-icon>
        <cds-icon shape="angle" direction="down"></cds-icon>
    </button>
    <ng-container *ngIf="(currentUser !== null && currentUser.email) else login">
      <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
        <div clrDropdownItem> {{currentUser.email}} </div>
        <div class="dropdown-divider" role="separator" aria-hidden="true"></div>
        <div clrDropdownItem>
          <a href="javascript:void(0);" (click)="logout()">
            <clr-icon shape="logout" class="is-solid"></clr-icon> {{'signout.title' | translate}}
          </a>
        </div>
    </clr-dropdown-menu>
    </ng-container>
    <ng-template #login>
      <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
        <div clrDropdownItem>
          <a (click)="onSignInClick()">
            <clr-icon shape="login" class="is-solid"></clr-icon> {{'signin.title' | translate}}
          </a>
        </div>
    </clr-dropdown-menu>
    </ng-template>
  </clr-dropdown> 
  </div>
  <!--<div class="switch-lang">
    <a href="javascript:void(0)" (click)="onLanguageClick()" style="text-decoration: none; color: white;">
      <cds-icon shape="world" style="width:17px;margin-top: -3px;"></cds-icon>
        {{selectedLanguage}}
    </a>
  </div>
  <div class="user-class">
    <clr-dropdown [clrCloseMenuOnItemClick]="false">
      <button clrDropdownTrigger aria-label="User">
          <cds-icon shape="user" size="15"></cds-icon>
          <cds-icon shape="angle" direction="down"></cds-icon>
      </button>
      <ng-container *ngIf="(currentUser !== null && currentUser.email) else login">
        <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
          <div clrDropdownItem> {{currentUser.email}} </div>
          <div class="dropdown-divider" role="separator" aria-hidden="true"></div>
          <div clrDropdownItem>
            <a href="javascript:void(0);" (click)="logout()">
              <clr-icon shape="logout" class="is-solid"></clr-icon> {{'signout.title' | translate}}
            </a>
          </div>
      </clr-dropdown-menu>
      </ng-container>
      <ng-template #login>
        <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
          <div clrDropdownItem>
            <a (click)="onSignInClick()">
              <clr-icon shape="login" class="is-solid"></clr-icon> {{'signin.title' | translate}}
            </a>
          </div>
      </clr-dropdown-menu>
      </ng-template>
    </clr-dropdown> 
  </div> -->
  
</header>
<clr-modal [(clrModalOpen)]="showLanguage" [clrModalSize]="'sm'">
  <h3 class="modal-title" style="font-size: 20px;">{{ 'lang.title' | translate }}</h3>
  <div class="modal-body">
    <a href="javascript:void(0)" [style.color]="selectedLanguage =='English'?'red':''" (click)="changeLanguage('en')">English</a><br>
    <a href="javascript:void(0)" [style.color]="selectedLanguage =='中文'?'red':''" (click)="changeLanguage('zh')">中文 (Chinese)</a><br>
    <a href="javascript:void(0)" [style.color]="selectedLanguage =='日本語'?'red':''" (click)="changeLanguage('ja')">日本語 (Japanese)</a><br>
    <a href="javascript:void(0)" [style.color]="selectedLanguage =='Español'?'red':''" (click)="changeLanguage('es')">Español (Spanish)</a><br>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="onCancelClick()">
        {{ 'cancel.title' | translate }}
      </button>
  </div>
</clr-modal>
<alert-dialog *ngIf="vcpmservice.showAlert" [alertProject]="vcpmservice.showAlert" [reDirectTo]="reDirectTo"(closeAlertDialog)="closeAlertDialog($event)"></alert-dialog>
