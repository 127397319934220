import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { CoreModule } from '@core/core.module';
import { SharedModule } from '@shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import '@cds/core/button/register.js';
import { ClarityModule } from '@clr/angular';
import { QuestionsComponent } from './questions/questions.component';
import { CdsModule } from '@cds/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ResultsComponent } from './results/results.component';
import { ChartsModule } from 'ng2-charts';
import { TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { AlertService } from '@core/services/alert.service';
import { Angulartics2Module } from 'angulartics2';


@NgModule({
  declarations: [AppComponent, QuestionsComponent, ResultsComponent],
  imports: [
    CoreModule,
    SharedModule,
    AppRoutingModule,
    BrowserModule,
    ClarityModule,
    BrowserAnimationsModule,
    CdsModule,
    ChartsModule,
    TranslateModule,
    Angulartics2Module.forRoot({
      pageTracking: {
        clearIds: false,
        clearQueryParams: true
      }
    })
  ],
  providers: [AlertService, { 
    provide: HTTP_INTERCEPTORS, 
    useClass: JwtInterceptor, 
    multi: true 
  }],
  bootstrap: [AppComponent],
})
export class AppModule { }
