import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClarityModule } from '@clr/angular';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './components/footer/footer.component';
import { NotFoundPageComponent } from './components/not-found-page/not-found-page.component';
import { DownloadDialogComponent } from './components/download-dialog/download-dialog.component';
import { CodeInputModule } from 'angular-code-input';
import { ClarityIcons, cloneIcon, pencilIcon, shareIcon, trashIcon, userIcon, worldIcon } from '@cds/core/icon';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NewProjectComponent } from './new-project/new-project.component';
import { LoginComponent } from './components/login/login.component';
import { SavedProjectsComponent } from './saved-projects/saved-projects.component';
import { CloneProjectComponent } from './clone-project/clone-project.component';
import { AlertComponent } from './alert/alert.component';
import { DeleteProjectComponent } from './delete-project/delete-project.component';
import { ShareProjectComponent } from './share-project/share-project.component';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { ReleaseNotesComponent } from './components/release-notes/release-notes.component';

ClarityIcons.addIcons(pencilIcon,worldIcon,userIcon,trashIcon,cloneIcon,shareIcon);

export function createTranslateHttpLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    NotFoundPageComponent,
    DownloadDialogComponent,
    NewProjectComponent,
    LoginComponent,
    SavedProjectsComponent,
    CloneProjectComponent,
    AlertComponent,
    DeleteProjectComponent,
    ShareProjectComponent,
    AlertDialogComponent,
    ReleaseNotesComponent,
  ],
  imports: [
    CommonModule,
    ClarityModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CodeInputModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateHttpLoader),
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    FormsModule,
    HeaderComponent,
    FooterComponent,
    CommonModule,
    ReactiveFormsModule,
    ClarityModule,
    NotFoundPageComponent,
    DownloadDialogComponent,
    NewProjectComponent,
    LoginComponent,
    SavedProjectsComponent,
    CloneProjectComponent,
    AlertComponent,
    DeleteProjectComponent
  ],
  providers: [],
})
export class SharedModule {}
