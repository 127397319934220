import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { VCPMResponse } from '@core/models/vcpmresponse';
import { AlertService } from '@core/services/alert.service';
import { VCPMService } from '@core/services/vcpm.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'delete-project',
  templateUrl: './delete-project.component.html',
  styleUrls: ['./delete-project.component.scss']
})
export class DeleteProjectComponent implements OnInit {
  @Input() deleteProject: boolean;
  @Output() closeDeleteProject = new EventEmitter<{deleted: boolean}>();
  deleteProjName: any;
  constructor(private vcpmService: VCPMService,private alertService: AlertService,private translateService: TranslateService) { 
    this.deleteProjName = this.vcpmService.vcpmSavedData.metadata.analysisName;
  }

  ngOnInit() {
  }
  modalHandler() {
    this.closeDeleteProject.emit({deleted: false});
  }
  removeProject() {
    this.vcpmService.deleteAnalysis(this.vcpmService.vcpmSavedData.projectId).subscribe((resp: VCPMResponse) => {
      // this.pushDataToSuperCollider(resp);
      this.closeDeleteProject.emit({ deleted: true });
    },error => {
      this.alertService.error(this.translateService.instant('apiFailErrorMsg.title'),false)
    });
  }
  pushDataToSuperCollider(res){
    let req = {
      "superColliderData": res,
      "configurationGroup": "vmcam",
    }
    this.vcpmService.pushDataToSuperCollider(req,res.projectId).subscribe(res => {
      this.closeDeleteProject.emit({ deleted: true });
    },error => {
      this.alertService.error(this.translateService.instant('apiFailErrorMsg.title'),false)
    });
  }
}
