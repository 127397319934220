<clr-modal [(clrModalOpen)]="deleteProject" [clrModalClosable]="false" [clrModalSize]="'md'">
    <h3 class="modal-title">{{'deleteAnalysistooltip.title' | translate}}
        <span class="close-icon">
            <clr-icon shape="times" (click)="modalHandler()"></clr-icon>
        </span>
    </h3>
    <div class="modal-body">
        {{'deletemsg.title' | translate}}<strong>{{deleteProjName}}</strong> ? 
    </div>
    <div class="modal-footer">
        <button class="btn btn-outline" (click)="modalHandler()">{{'cancel.title' | translate}}</button>
        <button class="btn btn-primary" (click)="removeProject()">{{'delete.title' | translate}}</button>
    </div>
</clr-modal>
<alert></alert>